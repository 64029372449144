import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import { AssignmentTurnedIn } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { keyframes } from "@emotion/react";

// Animations
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(30px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

// Styled Components
const StyledPaper = styled(Paper)({
  padding: "30px",
  margin: "auto",
  marginTop: "24px",
  width: "97%",
  borderRadius: "15px",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#fff",
  animation: `${slideIn} 0.5s ease-out`,
});

const StyledAppBar = styled(AppBar)({
  background: "linear-gradient(45deg, #0B1F3D 30%, #1A3C6B 90%)",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    transition: "all 0.3s ease",
    "&:hover": {
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    },
  },
});

const StyledButton = styled(Button)({
  borderRadius: "25px",
  padding: "10px 20px",
  backgroundColor: "#1976d2",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: "#115293",
    transform: "translateY(-2px)",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
  },
});

const StyledTableContainer = styled(TableContainer)({
  borderRadius: "10px",
  boxShadow: "0 4px 15px rgba(0, 0, 0, 0.05)",
  animation: `${fadeIn} 0.5s ease-in`,
});

const EmployeeAttendance = () => {
  const navigate = useNavigate();
  const [attendanceData, setAttendanceData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // For search results
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [yearOptions, setYearOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [attendanceDetails, setAttendanceDetails] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // For search input

  const token = sessionStorage.getItem("token");

  const fetchEmployeeData = async () => {
    try {
      setLoading(true);
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/Employee/GetEmployee`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
      });

      if (!response.ok) throw new Error("Failed to fetch employee data");
      const data = await response.json();
      setAttendanceData(data);
      setFilteredData(data); // Initialize filtered data
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchFinancialYears = async () => {
    const url = process.env.REACT_APP_BASE_URL;
    const apiUrl = `${url}/ClassPromotion/GetFinancialYear`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({}),
      });
      if (!response.ok) throw new Error("Failed to fetch financial years");
      const data = await response.json();
      setYearOptions(data);

      // Auto-select current financial year
      const today = new Date();
      const currentYear = today.getFullYear();
      const nextYear = currentYear + 1;

      const financialYearStart = new Date(currentYear, 3, 1); // April 1st
      const financialYearEnd = new Date(nextYear, 2, 31); // March 31st

      let currentSessionString;
      if (today >= financialYearStart && today <= financialYearEnd) {
        currentSessionString = `${currentYear}-${nextYear}`;
      } else {
        currentSessionString = `${currentYear - 1}-${currentYear}`;
      }

      const currentSession = data.find(
        (item) => item.finanacialYear === currentSessionString
      );
      if (currentSession) {
        setSelectedYear(currentSession.financialYearID);
      } else {
        setSelectedYear(data[0]?.financialYearID || ""); // Fallback to first option
      }
    } catch (error) {
      setError("Could not load financial years.");
    }
  };

  const fetchEmployeeAttendance = async (employeeId) => {
    try {
      setLoading(true);
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/EmployeeAttendance/GetEmployeeAttendance`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({ employeeId, yearId: selectedYear }),
      });

      if (!response.ok) throw new Error("Failed to fetch attendance details");
      const data = await response.json();
      setAttendanceDetails(data.attendanceData);
      setModalOpen(true);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (query) => {
    if (!query.trim()) {
      setFilteredData(attendanceData); // Reset to full list if search is empty
      return;
    }

    const lowerQuery = query.toLowerCase();
    const filtered = attendanceData.filter((employee) => {
      const employeeCode = employee.employeeCode?.toLowerCase() || "";
      const employeeName = employee.employeeName?.toLowerCase() || "";
      return (
        employeeCode.includes(lowerQuery) || employeeName.includes(lowerQuery)
      );
    });
    setFilteredData(filtered);
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    handleSearch(query);
  };

  useEffect(() => {
    fetchEmployeeData();
    fetchFinancialYears();
  }, []);

  const closeModal = () => setModalOpen(false);

  return (
    <Container sx={{ mt: 5 }}>
      <StyledAppBar position="static">
        <Toolbar>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", letterSpacing: 1 }}
          >
            Employee Attendance
          </Typography>
        </Toolbar>
      </StyledAppBar>

      <StyledPaper elevation={3}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ color: "#1976d2", fontWeight: "bold", textAlign: "center" }}
        >
          Employee Attendance
        </Typography>

        <Box sx={{ display: "flex", gap: 3, mb: 4 }}>
          <StyledTextField
            label="Search by Code or Name"
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <StyledTextField
            select
            label="Financial Year"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            fullWidth
            SelectProps={{ native: true }}
          >
            {yearOptions.map((year) => (
              <option key={year.financialYearID} value={year.financialYearID}>
                {year.finanacialYear}
              </option>
            ))}
          </StyledTextField>
        </Box>

        <StyledButton
          variant="contained"
          color="primary"
          sx={{ mb: 4 }}
          onClick={() => navigate("/empattendance-form")}
        >
          Add Attendance
        </StyledButton>

        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error" sx={{ textAlign: "center", my: 4 }}>
            {error}
          </Typography>
        ) : (
          <StyledTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Serial No</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Employee Code
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Employee Name
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Department</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Designation</TableCell>
                  <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.length > 0 ? (
                  filteredData.map((employee, index) => (
                    <TableRow
                      key={employee.employeeID}
                      sx={{
                        "&:hover": { backgroundColor: "#f5f5f5" },
                        transition: "background-color 0.2s ease",
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{employee.employeeCode}</TableCell>
                      <TableCell>{employee.employeeName}</TableCell>
                      <TableCell>{employee.departmentName}</TableCell>
                      <TableCell>{employee.designationName}</TableCell>
                      <TableCell align="center">
                        <Tooltip title="View Attendance">
                          <IconButton
                            color="primary"
                            onClick={() =>
                              fetchEmployeeAttendance(employee.employeeID)
                            }
                            sx={{
                              "&:hover": { transform: "scale(1.1)" },
                              transition: "transform 0.2s ease",
                            }}
                          >
                            <AssignmentTurnedIn />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No employees found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}

        {/* Attendance Modal */}
        <Dialog
          open={modalOpen}
          onClose={closeModal}
          maxWidth="lg"
          fullWidth
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "15px",
              boxShadow: "0 8px 32px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <DialogTitle
            sx={{
              background: "linear-gradient(45deg, #3f51b5 30%, #1976d2 90%)",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Attendance Register
          </DialogTitle>
          <DialogContent sx={{ p: 3 }}>
            {attendanceDetails && attendanceDetails.length > 0 ? (
              <TableContainer sx={{ maxHeight: 400, overflow: "auto" }}>
                <Table
                  stickyHeader
                  sx={{ minWidth: 800, border: "1px solid #e0e0e0" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          position: "sticky",
                          left: 0,
                          backgroundColor: "#f5f5f5",
                          zIndex: 1,
                        }}
                      >
                        Month
                      </TableCell>
                      {Array.from({ length: 31 }, (_, i) => (
                        <TableCell
                          key={i + 1}
                          sx={{
                            fontWeight: "bold",
                            textAlign: "center",
                            backgroundColor: "#f5f5f5",
                          }}
                        >
                          {i + 1}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(
                      attendanceDetails.reduce(
                        (acc, { month, day, attendanceStatus }) => {
                          if (!acc[month]) acc[month] = Array(31).fill("");
                          acc[month][day - 1] = attendanceStatus;
                          return acc;
                        },
                        {}
                      )
                    ).map(([month, statuses]) => (
                      <TableRow
                        key={month}
                        sx={{ "&:hover": { backgroundColor: "#f9f9f9" } }}
                      >
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            position: "sticky",
                            left: 0,
                            backgroundColor: "#f0f0f0",
                            zIndex: 1,
                          }}
                        >
                          {month}
                        </TableCell>
                        {statuses.map((status, index) => (
                          <TableCell
                            key={index}
                            sx={{
                              textAlign: "center",
                              color:
                                status === "Present"
                                  ? "green"
                                  : status === "Absent"
                                  ? "red"
                                  : status === "N/A"
                                  ? "#d3d3d3"
                                  : "black",
                              fontWeight: status ? "bold" : "normal",
                            }}
                          >
                            {status || "-"}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography sx={{ textAlign: "center", py: 2 }}>
                No attendance data available
              </Typography>
            )}
          </DialogContent>
          <DialogActions sx={{ backgroundColor: "#f5f5f5", p: 2 }}>
            <Button
              onClick={closeModal}
              sx={{
                backgroundColor: "#3f51b5",
                color: "white",
                "&:hover": { backgroundColor: "#2c387e" },
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </StyledPaper>
    </Container>
  );
};

export default EmployeeAttendance;
