import React, { useEffect, useState } from "react";
import {
  Modal,
  Typography,
  AppBar,
  Toolbar,
  Box,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { keyframes } from "@emotion/react";

// Animations
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(30px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const StyledModalBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
  padding: "40px",
  minWidth: "1000px",
  maxWidth: "95%",
  maxHeight: "90vh",
  overflowY: "auto",
  borderRadius: "20px",
  boxShadow: "0 12px 40px rgba(0, 0, 0, 0.25)",
  animation: `${fadeIn} 0.5s ease-in`,
});

const StyledAppBar = styled(AppBar)({
  background: "linear-gradient(45deg, #0B1F3D 30%, #1A3C6B 90%)",
  borderRadius: "15px 15px 0 0",
  marginBottom: "25px",
});

const ProfileCard = styled(Box)({
  border: "2px solid #e0e0e0",
  borderRadius: "15px",
  padding: "25px",
  backgroundColor: "#fafafa",
  animation: `${slideIn} 0.6s ease-out`,
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.02)",
  },
});

const AttendanceGrid = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(7, 1fr)", // 7 columns for a week-like layout
  gap: "8px",
  padding: "15px",
  backgroundColor: "#fff",
  borderRadius: "10px",
  boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
});

const StyledFormControl = styled(FormControl)({
  minWidth: "250px",
  marginBottom: "25px",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "scale(1.02)",
  },
});

const StyledAccordion = styled(Accordion)({
  borderRadius: "10px",
  marginBottom: "15px",
  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  "&:before": {
    display: "none",
  },
});

const StudentDetailsModal = ({ isOpen, handleClose, studentId }) => {
  const [studentProfile, setStudentProfile] = useState(null);
  const [monthsData, setMonthsData] = useState([]);
  const [totalAttendance, setTotalAttendance] = useState([]);
  const [classRecord, setClassRecord] = useState([]);
  const [financialYears, setFinancialYears] = useState([]);
  const [selectedYearId, setSelectedYearId] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchFinancialYears = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem("token");
      const response = await fetch(
        `${apiUrl}/ClassPromotion/GetFinancialYear`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({}),
        }
      );

      if (!response.ok) {
        throw new Error(`Error fetching financial years: ${response.status}`);
      }

      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        console.error("Record Not Found");
        return;
      }
      setFinancialYears(data);

      const today = new Date();
      const currentYear = today.getFullYear();
      const nextYear = currentYear + 1;

      const financialYearStart = new Date(currentYear, 3, 1);
      const financialYearEnd = new Date(nextYear, 2, 31);

      let currentSessionString;
      if (today >= financialYearStart && today <= financialYearEnd) {
        currentSessionString = `${currentYear}-${nextYear}`;
      } else {
        currentSessionString = `${currentYear - 1}-${currentYear}`;
      }

      const currentSession = data.find(
        (item) => item.finanacialYear === currentSessionString
      );
      if (currentSession) {
        setSelectedYearId(currentSession.financialYearID);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchStudentProfile = async () => {
    setLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${apiUrl}/Attendance/StudentProfile`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          studentId: studentId,
          yearId: selectedYearId,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setStudentProfile(data.objData);
        setMonthsData(data.objattendancelist || []);
        setTotalAttendance(data.totalAttendancList || []);
        setClassRecord(data.studentClassRecord || []);
      } else {
        console.error("Failed to fetch student profile");
      }
    } catch (error) {
      console.error("API request error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchFinancialYears();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && studentId && selectedYearId) {
      fetchStudentProfile();
    }
  }, [isOpen, studentId, selectedYearId]);

  const renderProfileDetails = () => {
    if (!studentProfile) return null;

    return (
      <ProfileCard>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              <strong>Admission No:</strong>{" "}
              {studentProfile.admissionNo || "N/A"}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Student Name:</strong>{" "}
              {studentProfile.studentName || "N/A"}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Class:</strong> {studentProfile.className || "N/A"}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Section:</strong> {studentProfile.sectionName || "N/A"}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Roll No:</strong> {studentProfile.rollNo || "N/A"}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Date of Birth:</strong>{" "}
              {studentProfile.studentDOB || "N/A"}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Admission Date:</strong>{" "}
              {studentProfile.admissionDate || "N/A"}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Email:</strong>{" "}
              {studentProfile.emailId === "undefined"
                ? "N/A"
                : studentProfile.emailId || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              <strong>Aadhar No:</strong> {studentProfile.adhaarNo || "N/A"}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Father's Name:</strong>{" "}
              {studentProfile.fathersName || "N/A"}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Father's Mobile:</strong>{" "}
              {studentProfile.fathersMobileNo || "N/A"}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Father's Income:</strong>{" "}
              {studentProfile.fatherIncome || "N/A"}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Mother's Name:</strong>{" "}
              {studentProfile.mothersName || "N/A"}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Mother's Mobile:</strong>{" "}
              {studentProfile.mothersMobileNo || "N/A"}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Mother's Income:</strong>{" "}
              {studentProfile.motherIncome || "N/A"}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Address:</strong> {studentProfile.address || "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </ProfileCard>
    );
  };

  const romanToNum = (roman) => {
    const romanMap = {
      i: 1,
      ii: 2,
      iii: 3,
      iv: 4,
      v: 5,
      vi: 6,
      vii: 7,
      viii: 8,
      ix: 9,
      x: 10,
      xi: 11,
      xii: 12,
      xiii: 13,
      xiv: 14,
      xv: 15,
      xvi: 16,
      xvii: 17,
      xviii: 18,
      xix: 19,
      xx: 20,
      xxi: 21,
      xxii: 22,
      xxiii: 23,
      xxiv: 24,
      xxv: 25,
      xxvi: 26,
      xxvii: 27,
      xxviii: 28,
      xxix: 29,
      xxx: 30,
      xxxi: 31,
    };
    return romanMap[roman.toLowerCase()] || 0;
  };

  const renderAttendanceHeatmap = () => {
    if (!monthsData.length)
      return <Typography>No attendance data available</Typography>;

    return monthsData.map((monthData, index) => (
      <StyledAccordion key={index} defaultExpanded={false}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" sx={{ color: "#1976d2" }}>
              {monthData.month}
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Chip
                label={`P: ${monthData.presentCount}`}
                color="success"
                variant="outlined"
              />
              <Chip
                label={`A: ${monthData.absentCount}`}
                color="error"
                variant="outlined"
              />
              <Chip
                label={`H: ${monthData.holidayCount}`}
                sx={{ backgroundColor: "#ffca28", color: "#fff" }}
                variant="outlined"
              />
              <Chip
                label={`${monthData.attendancePercentage}`}
                color="primary"
                variant="outlined"
              />
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <AttendanceGrid>
            {Array.from({ length: monthData.totalDays }, (_, i) => i + 1).map(
              (day) => {
                const romanDay = Object.keys(monthData).find(
                  (key) =>
                    romanToNum(key) === day &&
                    key !== "month" &&
                    key !== "totalDays" &&
                    key !== "presentCount" &&
                    key !== "absentCount" &&
                    key !== "holidayCount" &&
                    key !== "recordedDays" &&
                    key !== "attendancePercentage"
                );
                const status = romanDay ? monthData[romanDay] : "";
                let backgroundColor = "#e0e0e0"; // No data
                if (status === "P") backgroundColor = "#4caf50"; // Present
                else if (status === "A") backgroundColor = "#f44336"; // Absent
                else if (status === "H") backgroundColor = "#ffca28"; // Holiday (assuming "H" for holiday)

                return (
                  <Box
                    key={day}
                    sx={{
                      width: "40px",
                      height: "40px",
                      backgroundColor,
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#fff",
                      fontSize: "14px",
                      cursor: "pointer",
                      "&:hover": { opacity: 0.8, transform: "scale(1.1)" },
                      transition: "all 0.2s ease",
                    }}
                    title={`${day}: ${
                      status === "P"
                        ? "Present"
                        : status === "A"
                        ? "Absent"
                        : status === "H"
                        ? "Holiday"
                        : "No data"
                    }`}
                  >
                    {day}
                  </Box>
                );
              }
            )}
          </AttendanceGrid>
        </AccordionDetails>
      </StyledAccordion>
    ));
  };

  const renderSummary = () => {
    if (!totalAttendance.length && !classRecord.length) return null;

    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" sx={{ color: "#1976d2", mb: 2 }}>
          Summary
        </Typography>
        {totalAttendance.length > 0 && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6">Total Attendance</Typography>
            {totalAttendance.map((record, index) => (
              <Box
                key={index}
                sx={{
                  p: 2,
                  border: "1px solid #e0e0e0",
                  borderRadius: "10px",
                  mb: 1,
                }}
              >
                <Typography>Year: {record.year}</Typography>
                <Typography>Total Days: {record.totalDays}</Typography>
                <Typography>Present: {record.presentCount}</Typography>
                <Typography>Absent: {record.absentCount}</Typography>
                <Typography>Holidays: {record.holidayCount}</Typography>
                <Typography>
                  Percentage: {record.attendancePercentage}%
                </Typography>
              </Box>
            ))}
          </Box>
        )}
        {classRecord.length > 0 && (
          <Box>
            <Typography variant="h6">Class Transfer History</Typography>
            {classRecord.map((record, index) => (
              <Box
                key={index}
                sx={{
                  p: 2,
                  border: "1px solid #e0e0e0",
                  borderRadius: "10px",
                  mb: 1,
                }}
              >
                <Typography>
                  From: {record.fromClass} - {record.fromSection} (
                  {record.fromSession})
                </Typography>
                <Typography>
                  To: {record.toClass} - {record.toSection} ({record.toSession})
                </Typography>
                <Typography>Transfer Date: {record.trfDate}</Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <StyledModalBox>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
          <CloseIcon
            onClick={handleClose}
            sx={{
              cursor: "pointer",
              fontSize: 32,
              color: "#555",
              "&:hover": { color: "#000" },
            }}
          />
        </Box>
        <StyledAppBar position="static">
          <Toolbar>
            <Typography
              variant="h4"
              component="div"
              sx={{ fontWeight: "bold", letterSpacing: 1 }}
            >
              Student Profile
            </Typography>
          </Toolbar>
        </StyledAppBar>

        {loading && (
          <CircularProgress sx={{ display: "block", margin: "30px auto" }} />
        )}

        <StyledFormControl fullWidth>
          <InputLabel id="session-label">Session</InputLabel>
          <Select
            labelId="session-label"
            value={selectedYearId}
            onChange={(e) => setSelectedYearId(e.target.value)}
            label="Session"
          >
            {financialYears.map((year) => (
              <MenuItem key={year.financialYearID} value={year.financialYearID}>
                {year.finanacialYear}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>

        {studentProfile && (
          <Box sx={{ display: "flex", alignItems: "flex-start", mt: 4 }}>
            <Box
              sx={{
                border: "3px solid #e0e0e0",
                borderRadius: "20px",
                padding: "15px",
                mr: 4,
                backgroundColor: "#fff",
                boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s ease",
                "&:hover": { transform: "scale(1.05)" },
              }}
            >
              <img
                src={
                  studentProfile.studentImageUrl
                    ? `https://arizshad-002-site5.atempurl.com/${studentProfile.studentImageUrl}`
                    : "https://via.placeholder.com/250"
                }
                alt="Student"
                style={{
                  width: "250px",
                  height: "250px",
                  borderRadius: "15px",
                  objectFit: "cover",
                }}
              />
            </Box>
            {renderProfileDetails()}
          </Box>
        )}

        <StyledAppBar position="static" sx={{ mt: 5 }}>
          <Toolbar>
            <Typography
              variant="h4"
              component="div"
              sx={{ fontWeight: "bold", letterSpacing: 1 }}
            >
              Attendance Record
            </Typography>
          </Toolbar>
        </StyledAppBar>

        <Box sx={{ mt: 3 }}>
          {renderAttendanceHeatmap()}
          <Box
            sx={{ mt: 3, display: "flex", gap: 3, justifyContent: "center" }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  width: 25,
                  height: 25,
                  backgroundColor: "#4caf50",
                  mr: 1,
                  borderRadius: "5px",
                }}
              />
              <Typography>Present</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  width: 25,
                  height: 25,
                  backgroundColor: "#f44336",
                  mr: 1,
                  borderRadius: "5px",
                }}
              />
              <Typography>Absent</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  width: 25,
                  height: 25,
                  backgroundColor: "#ffca28",
                  mr: 1,
                  borderRadius: "5px",
                }}
              />
              <Typography>Holiday</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  width: 25,
                  height: 25,
                  backgroundColor: "#e0e0e0",
                  mr: 1,
                  borderRadius: "5px",
                }}
              />
              <Typography>No Data</Typography>
            </Box>
          </Box>
        </Box>

        {renderSummary()}
      </StyledModalBox>
    </Modal>
  );
};

export default StudentDetailsModal;
