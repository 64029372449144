import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
} from "@mui/material";

const AttendanceCalender = ({ isOpen, handleClose, studentId }) => {
  const [studentProfile, setStudentProfile] = useState(null);
  const [monthsData, setMonthsData] = useState([]);
  const [financialYears, setFinancialYears] = useState([]);
  const [selectedYearID, setSelectedYearID] = useState(null);
  const [registrationData, setRegistrationData] = useState({});

  // Get the current month name (e.g., "Jan", "Feb", etc.)
  const getCurrentMonth = () => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const currentDate = new Date();
    return monthNames[currentDate.getMonth()];
  };

  useEffect(() => {
    const fetchFinancialYears = async () => {
      try {
        const apiUrl = process.env.REACT_APP_BASE_URL;
        const token = sessionStorage.getItem("token");

        const response = await fetch(
          `${apiUrl}/ClassPromotion/GetFinancialYear`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({}),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch financial years");
        }

        const data = await response.json();
        if (data.data === null && data.msg === "Record Not Found") {
          console.error("Record Not Found");
          return;
        }

        setFinancialYears(data);

        const today = new Date();
        const currentYear = today.getFullYear();
        const nextYear = currentYear + 1;

        const financialYearStart = new Date(currentYear, 3, 1); // April 1st
        const financialYearEnd = new Date(nextYear, 2, 31); // March 31st

        let currentSessionString;
        if (today >= financialYearStart && today <= financialYearEnd) {
          currentSessionString = `${currentYear}-${nextYear}`;
        } else {
          currentSessionString = `${currentYear - 1}-${currentYear}`;
        }

        const currentSession = data.find(
          (item) => item.finanacialYear === currentSessionString
        );

        if (currentSession) {
          setSelectedYearID(currentSession.financialYearID);
          setRegistrationData((prevData) => ({
            ...prevData,
            financialYearID: currentSession.financialYearID,
          }));
        }
      } catch (error) {
        console.error("Error fetching financial years:", error);
      }
    };

    fetchFinancialYears();
  }, []);

  useEffect(() => {
    if (!selectedYearID) return;

    const fetchStudentProfile = async () => {
      try {
        const apiUrl = process.env.REACT_APP_BASE_URL;
        const token = sessionStorage.getItem("token");
        const studentID = sessionStorage.getItem("employeeId");

        const response = await fetch(`${apiUrl}/Attendance/StudentProfile`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            studentId: studentID,
            yearId: selectedYearID,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data.status === null && data.msg === "Record Not Found") {
            setMonthsData([]);
            return;
          }
          setStudentProfile(data.objData);
          setMonthsData(data.objattendancelist);
        } else {
          console.error("Failed to fetch student profile");
        }
      } catch (error) {
        console.error("API request error:", error);
      }
    };
    fetchStudentProfile();
  }, [selectedYearID]);

  return (
    <div className="my-progress">
      <AppBar
        position="static"
        style={{
          backgroundColor: "#0B1F3D",
          marginTop: "20px",
          textAlign: "center",
          maxWidth: "990px",
          margin: "0 auto",
        }}
      >
        <Toolbar style={{ padding: "8px 16px", minHeight: "50px" }}>
          <Typography variant="h6" component="div">
            Attendance Record
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Financial Year Dropdown */}
      <div style={{ textAlign: "center", margin: "20px 0" }}>
        <Select
          value={selectedYearID || ""}
          onChange={(e) => setSelectedYearID(e.target.value)}
          displayEmpty
        >
          <MenuItem value="" disabled>
            Select Session
          </MenuItem>
          {financialYears.map((year) => (
            <MenuItem key={year.financialYearID} value={year.financialYearID}>
              {year.finanacialYear}
            </MenuItem>
          ))}
        </Select>
      </div>

      {/* Attendance Record Table */}
      <TableContainer
        style={{
          marginTop: "20px",
          maxWidth: "1400px",
          margin: "auto",
          width: "950px",
        }}
      >
        <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ border: "1px solid #ddd", backgroundColor: "#f2f2f2" }}
              >
                Month
              </TableCell>
              {[...Array(31).keys()].map((day) => (
                <TableCell
                  key={day + 1}
                  style={{
                    border: "1px solid #ddd",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  {day + 1}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {monthsData.length > 0 ? (
              monthsData.map((month, index) => (
                <TableRow key={month.month}>
                  <TableCell
                    style={{
                      border: "1px solid #ddd",
                      backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
                    }}
                  >
                    {month.month}
                  </TableCell>
                  {[...Array(31).keys()].map((day) => (
                    <TableCell
                      key={day + 1}
                      style={{
                        border: "1px solid #ddd",
                        backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
                      }}
                    >
                      {month[Object.keys(month)[day + 1]]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={32}
                  style={{ textAlign: "center", padding: "20px" }}
                >
                  No attendance data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* All Months Attendance Summary */}
      {monthsData.length > 0 && (
        <TableContainer
          style={{
            marginTop: "20px",
            maxWidth: "1400px",
            margin: "auto",
            width: "950px",
          }}
        >
          <Typography
            variant="h6"
            style={{ margin: "20px 0", textAlign: "center" }}
          >
            Attendance Summary for All Months
          </Typography>
          <Table
            style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Month
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Total Days
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Present Count
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Absent Count
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Holiday Count
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Attendance Percentage
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {monthsData.map((month, index) => (
                <TableRow key={month.month}>
                  <TableCell
                    style={{
                      border: "1px solid #ddd",
                      backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
                    }}
                  >
                    {month.month}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #ddd",
                      backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
                    }}
                  >
                    {month.totalDays}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #ddd",
                      backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
                    }}
                  >
                    {month.presentCount}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #ddd",
                      backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
                    }}
                  >
                    {month.absentCount}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #ddd",
                      backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
                    }}
                  >
                    {month.holidayCount}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #ddd",
                      backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
                    }}
                  >
                    {month.attendancePercentage}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default AttendanceCalender;
