import React, { useEffect, useState } from "react";
import {
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Container,
  AppBar,
  Toolbar,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { keyframes } from "@emotion/react";

// Animation for sliding in the paper
const slideIn = keyframes`
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Animation for fading in the table
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const CenteredPaper = styled(Paper)({
  padding: "30px",
  maxWidth: "1400px",
  margin: "40px auto",
  borderRadius: "15px",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#fff",
  animation: `${slideIn} 0.5s ease-out`,
});

const StyledFormControl = styled(FormControl)({
  margin: "12px",
  minWidth: "220px",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "scale(1.02)",
  },
});

const StyledButton = styled(Button)({
  marginTop: "20px",
  padding: "12px 24px",
  borderRadius: "25px",
  backgroundColor: "#1976d2",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: "#115293",
    transform: "translateY(-2px)",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
  },
});

const StyledAppBar = styled(AppBar)({
  background: "linear-gradient(45deg, #0B1F3D 30%, #1A3C6B 90%)",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
});

const StyledTableContainer = styled(TableContainer)({
  animation: `${fadeIn} 0.5s ease-in`,
  borderRadius: "10px",
  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
});

const AddAttendance = () => {
  const [teachersList, setTeacherList] = useState([]);
  const [selectedTeacherId, setSelectedTeacherId] = useState("");
  const [classList, setClassList] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState("");
  const [sectionList, setSectionList] = useState([]);
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [studentList, setStudentList] = useState([]);
  const [isStudentDataFetched, setIsStudentDataFetched] = useState(false);
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]); // Set to current date
  const [attendanceMap, setAttendanceMap] = useState({});
  const [selectAllPresent, setSelectAllPresent] = useState(false);
  const [streamsList, setStreamsList] = useState([]);
  const [selectedStream, setSelectedStream] = useState("");
  const [showStreamDropdown, setShowStreamDropdown] = useState(false);
  const [shouldFetchStudents, setShouldFetchStudents] = useState(false);
  const [noStudentsMessage, setNoStudentsMessage] =
    useState("No students found");
  const [loading, setLoading] = useState(false);

  const fetchTeachers = async () => {
    setLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${apiUrl}/Attendance/GetTeacherList`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        throw new Error(`Error fetching subjects: ${response.status}`);
      }
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        return;
      }
      setTeacherList(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchStreams = async () => {
    setLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${apiUrl}/Stream/GetAllStreams`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ streamID: 0 }),
      });

      if (!response.ok) {
        throw new Error(`Error fetching streams: ${response.status}`);
      }

      const data = await response.json();
      if (!data || !Array.isArray(data)) {
        setStreamsList([]);
        return;
      }

      setStreamsList(data);
    } catch (error) {
      console.error("Error fetching streams:", error);
      setStreamsList([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchClasses = async (teacherId) => {
    setLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${apiUrl}/Teacher/GetClass`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ teacherId }),
      });

      if (!response.ok) {
        throw new Error(`Error fetching subjects: ${response.status}`);
      }
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        return;
      }
      setClassList(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSections = async (classId, teacherId) => {
    setLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${apiUrl}/Attendance/GetSectionById`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ classId, teacherId }),
      });

      if (!response.ok) {
        throw new Error(`Error fetching subjects: ${response.status}`);
      }
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        return;
      }
      setSectionList(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchStudents = async (classId, sectionId, streamName) => {
    setLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${apiUrl}/Attendance/GetStudentById`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          classId,
          sectionId,
          stream: streamName === "all" ? "0" : streamName || "0",
        }),
      });

      if (!response.ok) {
        throw new Error(`Error fetching students: ${response.status}`);
      }

      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        setStudentList([]);
        setIsStudentDataFetched(false);
        setNoStudentsMessage(
          selectedStream
            ? `No students found for ${selectedStream} stream`
            : "No students found"
        );
        return;
      }

      if (Array.isArray(data)) {
        setStudentList(data);
        setIsStudentDataFetched(true);
        setNoStudentsMessage("No students found");
      } else {
        console.error("API response is not an array:", data);
        setStudentList([]);
        setIsStudentDataFetched(false);
        setNoStudentsMessage(
          selectedStream
            ? `No students found for ${selectedStream} stream`
            : "No students found"
        );
      }
    } catch (error) {
      console.error("Error fetching students:", error);
      setStudentList([]);
      setIsStudentDataFetched(false);
      setNoStudentsMessage(
        selectedStream
          ? `No students found for ${selectedStream} stream`
          : "No students found"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeachers();
    fetchStreams();
  }, []);

  useEffect(() => {
    if (selectedTeacherId) {
      fetchClasses(selectedTeacherId);
    }
  }, [selectedTeacherId]);

  useEffect(() => {
    if (selectedClassId && selectedTeacherId) {
      fetchSections(selectedClassId, selectedTeacherId);
    }
  }, [selectedClassId, selectedTeacherId]);

  useEffect(() => {
    if (shouldFetchStudents && selectedClassId && selectedSectionId) {
      if (showStreamDropdown && !selectedStream) {
        setStudentList([]);
        setIsStudentDataFetched(false);
        setNoStudentsMessage("Please select a stream");
        return;
      }
      fetchStudents(selectedClassId, selectedSectionId, selectedStream);
    }
  }, [
    selectedClassId,
    selectedSectionId,
    selectedStream,
    shouldFetchStudents,
    showStreamDropdown,
  ]);

  useEffect(() => {
    if (!selectedClassId) {
      setShowStreamDropdown(false);
      setShouldFetchStudents(false);
      return;
    }

    const selectedClass = classList.find(
      (classItem) => classItem.classId === selectedClassId
    );

    if (!selectedClass) {
      setShowStreamDropdown(false);
      setShouldFetchStudents(false);
      return;
    }

    const classNumberMatch = selectedClass.className.match(/\d+/);
    if (!classNumberMatch) {
      setShowStreamDropdown(false);
      setShouldFetchStudents(false);
      return;
    }

    const classNumber = parseInt(classNumberMatch[0], 10);
    const shouldShowStream = classNumber >= 10;
    setShowStreamDropdown(shouldShowStream);

    setSelectedStream("");

    if (!shouldShowStream) {
      setShouldFetchStudents(true);
    } else {
      setShouldFetchStudents(false);
    }
  }, [selectedClassId, classList]);

  const handleAttendanceChange = (e, studentId) => {
    const newAttendanceMap = { ...attendanceMap };
    if (newAttendanceMap[studentId] === e.target.value) {
      delete newAttendanceMap[studentId];
    } else {
      newAttendanceMap[studentId] = e.target.value;
    }
    setAttendanceMap(newAttendanceMap);
  };

  const handleSelectAllPresent = () => {
    setAttendanceMap((prevAttendanceMap) => {
      if (selectAllPresent) {
        return {};
      } else {
        const newAttendanceMap = {};
        studentList.forEach((student) => {
          newAttendanceMap[student.studentId] = "present";
        });
        return newAttendanceMap;
      }
    });
    setSelectAllPresent((prev) => !prev);
  };

  const handleClearSelection = (studentId) => {
    const newAttendanceMap = { ...attendanceMap };
    delete newAttendanceMap[studentId];
    setAttendanceMap(newAttendanceMap);
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const formatDate = (date) => {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  };

  const resetForm = () => {
    setSelectedTeacherId("");
    setSelectedClassId("");
    setSelectedSectionId("");
    setSelectedStream("");
    setStudentList([]);
    setAttendanceMap({});
    setSelectAllPresent(false);
    setShouldFetchStudents(false);
    setIsStudentDataFetched(false);
    setNoStudentsMessage("No students found");
    setDate(new Date().toISOString().split("T")[0]); // Reset to current date
  };

  const handleSubmit = async () => {
    if (!date) {
      alert("Please fill the date field.");
      return;
    }

    setLoading(true);
    try {
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/Attendance`;
      const token = sessionStorage.getItem("token");
      const requestBody = {
        classId: selectedClassId,
        sectionId: selectedSectionId,
        attendenceDate: formatDate(date),
        attendence: studentList.map((student) => ({
          studentId: student.studentId,
          isAbsent: attendanceMap[student.studentId] === "absent",
          isPresent: attendanceMap[student.studentId] === "present",
        })),
      };

      if (Object.keys(attendanceMap).length === 0) {
        requestBody.attendence.forEach((entry) => {
          entry.isPresent = false;
          entry.isAbsent = false;
        });
      }

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        alert("Attendance data posted successfully");
        resetForm(); // Reset form on success
      } else {
        alert("Failed to post attendance data");
      }
    } catch (error) {
      alert("API request error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleStreamChange = (e) => {
    setSelectedStream(e.target.value);
    if (showStreamDropdown) {
      setShouldFetchStudents(true);
    }
  };

  return (
    <Container sx={{ marginTop: 5 }}>
      <StyledAppBar position="static">
        <Toolbar>
          <Typography variant="h4" component="div" sx={{ fontWeight: "bold" }}>
            Student Attendance
          </Typography>
        </Toolbar>
      </StyledAppBar>
      <CenteredPaper elevation={3}>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{ color: "#1976d2", fontWeight: "bold" }}
        >
          Attendance Form
        </Typography>
        {loading && (
          <CircularProgress
            sx={{ display: "block", margin: "20px auto" }}
            color="primary"
          />
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <StyledFormControl fullWidth>
              <InputLabel id="teacher-label">Teacher</InputLabel>
              <Select
                labelId="teacher-label"
                id="teacher-select"
                value={selectedTeacherId}
                onChange={(e) => {
                  setSelectedTeacherId(e.target.value);
                  setSelectedClassId("");
                  setSelectedSectionId("");
                  setSelectedStream("");
                  setStudentList([]);
                  setShouldFetchStudents(false);
                  setNoStudentsMessage("No students found");
                }}
                label="Teacher"
              >
                {teachersList.map((item) => (
                  <MenuItem key={item.employeeId} value={item.employeeId}>
                    {item.employeeName}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <StyledFormControl fullWidth>
              <InputLabel id="class-label">Class</InputLabel>
              <Select
                labelId="class-label"
                id="class-select"
                value={selectedClassId}
                onChange={(e) => {
                  setSelectedClassId(e.target.value);
                  setSelectedSectionId("");
                  setSelectedStream("");
                  setStudentList([]);
                  setNoStudentsMessage("No students found");
                }}
                label="Class"
              >
                {classList.map((classItem) => (
                  <MenuItem key={classItem.classId} value={classItem.classId}>
                    {classItem.className}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <StyledFormControl fullWidth>
              <InputLabel id="section-label">Section</InputLabel>
              <Select
                labelId="section-label"
                id="section-select"
                value={selectedSectionId}
                onChange={(e) => {
                  setSelectedSectionId(e.target.value);
                  if (!showStreamDropdown) {
                    setShouldFetchStudents(true);
                  }
                  setNoStudentsMessage("No students found");
                }}
                label="Section"
              >
                {Array.isArray(sectionList) && sectionList.length > 0 ? (
                  sectionList.map((sectionItem) => (
                    <MenuItem
                      key={sectionItem.sectionId}
                      value={sectionItem.sectionId}
                    >
                      {sectionItem.sectionName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No sections found</MenuItem>
                )}
              </Select>
            </StyledFormControl>
          </Grid>

          {showStreamDropdown && (
            <Grid item xs={12} sm={6}>
              <StyledFormControl fullWidth>
                <InputLabel id="stream-label">Stream</InputLabel>
                <Select
                  labelId="stream-label"
                  id="stream-select"
                  value={selectedStream}
                  onChange={handleStreamChange}
                  label="Stream"
                >
                  <MenuItem value="">Select Stream</MenuItem>
                  <MenuItem value="all">All Streams</MenuItem>
                  {Array.isArray(streamsList) && streamsList.length > 0 ? (
                    streamsList.map((stream) => (
                      <MenuItem key={stream.streamID} value={stream.streamName}>
                        {stream.streamName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No streams available</MenuItem>
                  )}
                </Select>
              </StyledFormControl>
            </Grid>
          )}

          <Grid item xs={12} sm={6}>
            <StyledFormControl fullWidth>
              <TextField
                id="date"
                label="Date"
                type="date"
                value={date}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleDateChange}
              />
            </StyledFormControl>
          </Grid>
        </Grid>

        {isStudentDataFetched && (
          <div
            className="my-6"
            style={{ maxHeight: "400px", overflowY: "scroll" }}
          >
            <Typography
              variant="h6"
              align="center"
              gutterBottom
              sx={{ color: "#1976d2" }}
            >
              Student List
            </Typography>
            <Typography variant="h6" align="end" gutterBottom>
              <FormControlLabel
                control={
                  <Radio
                    checked={selectAllPresent}
                    onClick={handleSelectAllPresent}
                  />
                }
                label="Mark All Present"
              />
            </Typography>
            <StyledTableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Serial</TableCell>
                    <TableCell>Student Name</TableCell>
                    <TableCell>Roll No</TableCell>
                    <TableCell>Mobile No</TableCell>
                    <TableCell>Stream</TableCell>
                    <TableCell>Attendance</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(studentList) && studentList.length > 0 ? (
                    studentList.map((student, index) => (
                      <TableRow key={student.studentId}>
                        <TableCell></TableCell>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{student.studentName}</TableCell>
                        <TableCell>{student.rollNo}</TableCell>
                        <TableCell>{student.mobileNo}</TableCell>
                        <TableCell>{student.stream}</TableCell>
                        <TableCell>
                          <RadioGroup
                            value={attendanceMap[student.studentId] || ""}
                            onChange={(e) =>
                              handleAttendanceChange(e, student.studentId)
                            }
                          >
                            <FormControlLabel
                              value="present"
                              control={<Radio />}
                              label="Present"
                            />
                            <FormControlLabel
                              value="absent"
                              control={<Radio />}
                              label="Absent"
                            />
                          </RadioGroup>
                          <Button
                            onClick={() =>
                              handleClearSelection(student.studentId)
                            }
                            size="small"
                            color="secondary"
                          >
                            Clear
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        {noStudentsMessage}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </div>
        )}
        <StyledButton
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "Submitting..." : "Mark Attendance"}
        </StyledButton>
      </CenteredPaper>
    </Container>
  );
};

export default AddAttendance;
