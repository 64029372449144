import {
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";

let debounceTimer;

function FeeDeposit() {
  const [ddlClass, setDdlClass] = useState([]);
  const [isPartialPayment, setIsPartialPayment] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showMoodal, setShowMoodal] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedStudentId, setSelectedStudentId] = useState(0);
  const [monthFeeDetails, setMonthFeeDetails] = useState([]);
  const [admissionData, setAdmissionData] = useState([]);
  const [chargeDetails, setChargeDetails] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("");
  const [selectedPaymentModeName, setSelectedPaymentModeName] = useState("");
  const [loadingBarProgress, setLoadingBarProgress] = useState("");
  const [totalFine, setTotalFine] = useState("0.00");
  const [totalAmount, setTotalAmount] = useState("");
  const [discountAmount, setDiscountAmount] = useState("0.00");
  const [concessionAmount, setConcessionAmount] = useState("0.00");
  const [amountAfterDiscount, setAmountAfterDiscount] = useState("");
  const [advanceAmount, setAdvanceAmount] = useState("0.00");
  const [walleteAmount, setWalleteAmount] = useState("0.00");
  const [paidAmount, setPaidAmount] = useState("0.00");
  const [balance, setBalance] = useState(0);
  const [studentId, setStudentId] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [monthFee, setMonthFee] = useState([]);
  const [feeCharge, setFeeCharge] = useState([]);
  const [printData, setPrintData] = useState(null);
  const [noDataFound, setNoDataFound] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [extraAmount, setExtraAmount] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showMonthDialog, setShowMonthDialog] = useState(false);
  const [showPaidAmountDialog, setShowPaidAmountDialog] = useState(false);
  const [prevPaidAmount, setPrevPaidAmount] = useState(0);
  const [sessions, setSessions] = useState([]); // State for session dropdown
  const [selectedSession, setSelectedSession] = useState(""); // State for selected session

  const [studentDetails, setStudentDetails] = useState({
    admissionNo: "",
    receiptDate: new Date().toISOString().substr(0, 10),
    className: "",
    studentName: "",
    fatherName: "",
    sectionName: "",
    classId: "",
  });

  const [formValues, setFormValues] = useState({
    admissionNo: "",
    className: "",
    studentName: "",
    fatherName: "",
    sectionName: "",
    totalAmount: "",
    paidAmount: "",
    discountAmount: "",
    balance: "",
    paymentMode: "",
    concessionAmount: "",
    previousPaidAmount: "",
  });

  const resetForm = () => {
    setStudentDetails({});
    setFormValues({
      totalAmount: "",
      paidAmount: "",
      discountAmount: "",
      balance: "",
      paymentMode: "",
      concessionAmount: "",
      previousPaidAmount: "",
    });
    setChargeDetails([]);
    setMonthFeeDetails([]);
    setAdmissionData([]);
    setNoDataFound(false);
  };

  const handleCloseMonthDialog = () => setShowMonthDialog(false);
  const handleClosePaidAmountDialog = () => setShowPaidAmountDialog(false);

  const handleMonthFeeChange = (index, fieldName, value) => {
    const updatedMonthFee = [...monthFee];
    updatedMonthFee[index][fieldName] = value;
    setMonthFee(updatedMonthFee);
  };

  const handleFeeChargeChange = (index, fieldName, value) => {
    const updatedFeeCharge = [...feeCharge];
    updatedFeeCharge[index][fieldName] = value;
    setFeeCharge(updatedFeeCharge);
  };

  const fetchSessions = async () => {
    try {
      setLoadingBarProgress(30);
      const response = await fetch(
        "https://arizshad-002-site5.ktempurl.com/api/ClassPromotion/GetFinancialYear",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("token"),
          },
        }
      );

      if (!response.ok) {
        setLoadingBarProgress(0);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setLoadingBarProgress(100);
      const data = await response.json();

      if (Array.isArray(data)) {
        setSessions(data);

        const today = new Date();
        const currentYear = today.getFullYear();
        const nextYear = currentYear + 1;

        const financialYearStart = new Date(currentYear, 3, 1);
        const financialYearEnd = new Date(nextYear, 2, 31);

        let currentSessionString;
        if (today >= financialYearStart && today <= financialYearEnd) {
          currentSessionString = `${currentYear}-${nextYear}`;
        } else {
          currentSessionString = `${currentYear - 1}-${currentYear}`;
        }

        const currentSession = data.find(
          (item) => item.finanacialYear === currentSessionString
        );

        if (currentSession) {
          setSelectedSession(currentSession.financialYearID);
        }
      }
    } catch (error) {
      console.error("Error fetching financial years:", error);
    }
  };

  const fetchClass = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${apiUrl}/Enquiry/ddlClassName`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({}),
      });
      if (!response.ok) {
        throw new Error(`Error fetching classes: ${response.status}`);
      }
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        throw new Error("Record Not Found");
      }
      setDdlClass(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBank = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${apiUrl}/Bank/GetBank`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({}),
      });
      if (!response.ok) {
        throw new Error(`Error fetching banks: ${response.status}`);
      }
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        throw new Error("Record Not Found");
      }
      setBankData(data);

      const cashBank = data.find(
        (item) => item.bankName.toLowerCase() === "cash"
      );
      if (cashBank) {
        setSelectedPaymentMode(cashBank.bankId);
        setSelectedPaymentModeName(cashBank.bankName);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePaymentModeChange = (event) => {
    const selectedBankId = event.target.value;
    const selectedBank = bankData.find(
      (bank) => bank.bankId.toString() === selectedBankId
    );
    setSelectedPaymentMode(selectedBankId);
    setSelectedPaymentModeName(selectedBank?.bankName || "");
  };

  const handleClassChange = (event) => {
    const classId = event.target.value;
    setSelectedClass(classId);
    fetchAdmission(classId);
  };

  const fetchAdmission = async (classId) => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem("token");
      const endpoint = isPartialPayment
        ? "SerachAdmissionListMonthly"
        : "SerachAdmissionListAnual";
      setMonthFeeDetails([]);
      const response = await fetch(`${apiUrl}/FeeDeposit/${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          classId: classId || 0,
        }),
      });
      if (!response.ok) {
        throw new Error(`Error fetching admission: ${response.status}`);
      }
      const data = await response.json();
      if (data.status === null && data.msg === "Record Not Found") {
        setNoDataFound(true);
        setAdmissionData([]);
      } else {
        setNoDataFound(false);
        setAdmissionData(data);
      }
      setShowModal(true);
    } catch (error) {
      console.error(error);
      setNoDataFound(true);
      setShowModal(true);
    }
  };

  const fetchMonthFeeDetails = async (studentId, sessionId) => {
    try {
      const url = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem("token");
      const endpoint = isPartialPayment
        ? "GetMonthFeeDetails"
        : "GetAnualFeeDetails";
      const apiUrl = `${url}/FeeDeposit/${endpoint}`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          studentId: studentId,
          sessionId: sessionId || selectedSession,
        }),
      });
      if (!response.ok) {
        throw new Error(`Error fetching month fee details: ${response.status}`);
      }
      const data = await response.json();
      if (data.status === null && data.msg === "Record Not Found") {
        setMonthFeeDetails([]);
        setNoDataFound(true);
      } else {
        setMonthFeeDetails(data);
        setNoDataFound(false);
      }
      setSelectedClass("");
    } catch (error) {
      console.error(error);
      setMonthFeeDetails([]);
      setNoDataFound(true);
    }
  };

  const fetchStudentDetails = async (studentId) => {
    try {
      const token = sessionStorage.getItem("token");
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/FeeDeposit/GetAmountbyStudentId`;
      const currentDate = new Date();
      const formattedDate = `${currentDate.getDate()}/${
        currentDate.getMonth() + 1
      }/${currentDate.getFullYear()}`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          studentId: studentId,
          monthId: 0,
          recieptDate: formattedDate,
        }),
      });
      if (!response.ok) {
        throw new Error(`Error fetching student details: ${response.status}`);
      }
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        throw new Error("Record Not Found");
      }
      setStudentDetails(data.objData);
      setStudentId(studentId);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCheckboxChange = (event) => {
    setIsPartialPayment(event.target.value === "Partial Payment");
  };

  const handleSearchButtonClick = () => {
    fetchAdmission();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedClass("0");
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = admissionData.filter((item) =>
    item.studentName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectButtonClick = (studentId) => {
    setSelectedStudentId(studentId);
    fetchMonthFeeDetails(studentId, selectedSession);
    fetchStudentDetails(studentId);
    setShowModal(false);
    resetForm();
  };

  const handleAmountChange = (index, newValue) => {
    const updatedChargeDetails = [...chargeDetails];
    const newAmount = parseFloat(newValue) || 0;
    updatedChargeDetails[index].chargerAmount = newAmount;
    setChargeDetails(updatedChargeDetails);

    const updatedMonthFeeDetails = [...monthFeeDetails];
    let newTotalAmount = 0;
    let newTotalFine = 0;
    let calculatedPrevPaidAmount = 0;

    updatedMonthFeeDetails.forEach((item) => {
      if (item.isChecked) {
        const relatedCharges = updatedChargeDetails.filter(
          (charge) => charge.monthId === item.monthId
        );
        const totalChargeAmount = relatedCharges.reduce(
          (sum, charge) => sum + charge.chargerAmount,
          0
        );
        const calculatedAmount =
          totalChargeAmount + item.fine - item.paidAmount;
        calculatedPrevPaidAmount += item.paidAmount;
        newTotalAmount += Math.max(0, calculatedAmount);
        newTotalFine += item.fine;

        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
          if (calculatedAmount < 0) {
            setIsDialogOpen(true);
          }
        }, 3000);
      }
    });

    setTotalAmount(newTotalAmount);
    setTotalFine(newTotalFine);
    setPrevPaidAmount(calculatedPrevPaidAmount);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleCheckboxesChange = async (index, isChecked) => {
    const updatedMonthFeeDetails = [...monthFeeDetails];
    updatedMonthFeeDetails[index].isChecked = isChecked;
    setMonthFeeDetails(updatedMonthFeeDetails);

    if (isChecked && !selectedMonth) {
      const item = monthFeeDetails[index];
      try {
        const token = sessionStorage.getItem("token");
        const url = process.env.REACT_APP_BASE_URL;
        const apiUrl = `${url}/FeeDeposit/GetClassCharger_MonthFee`;
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            feePaymentId: item.feePaymentId,
            studentId: selectedStudentId,
            classId: studentDetails.classId,
            monthId: item.monthId,
          }),
        });
        if (!response.ok) {
          throw new Error(
            `Error fetching month fee details: ${response.status}`
          );
        }
        const data = await response.json();

        const updatedChargeDetails = [...chargeDetails];
        data.forEach((newCharge) => {
          const existingChargeIndex = updatedChargeDetails.findIndex(
            (charge) => charge.chargerId === newCharge.chargerId
          );
          if (existingChargeIndex !== -1) {
            updatedChargeDetails[existingChargeIndex].chargerAmount +=
              newCharge.chargerAmount;
          } else {
            updatedChargeDetails.push(newCharge);
          }
        });
        setChargeDetails(updatedChargeDetails);

        const fineAmount = await fetchFineAmount(
          selectedStudentId,
          item.monthId,
          item.date
        );
        updatedMonthFeeDetails[index].fine = fineAmount;
        setMonthFeeDetails(updatedMonthFeeDetails);
      } catch (error) {
        console.error(error);
      }
    } else {
      const item = monthFeeDetails[index];
      try {
        const token = sessionStorage.getItem("token");
        const url = process.env.REACT_APP_BASE_URL;
        const apiUrl = `${url}/FeeDeposit/GetClassCharger_MonthFee`;
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            feePaymentId: item.feePaymentId,
            studentId: selectedStudentId,
            classId: studentDetails.classId,
            monthId: item.monthId,
          }),
        });
        if (!response.ok) {
          throw new Error(
            `Error fetching month fee details: ${response.status}`
          );
        }
        const data = await response.json();

        const updatedChargeDetails = [...chargeDetails];
        data.forEach((charge) => {
          const existingChargeIndex = updatedChargeDetails.findIndex(
            (c) => c.chargerId === charge.chargerId
          );
          if (existingChargeIndex !== -1) {
            updatedChargeDetails[existingChargeIndex].chargerAmount -=
              charge.chargerAmount;
          }
        });
        setChargeDetails(updatedChargeDetails);
      } catch (error) {
        console.error(error);
      }
    }

    const allUnchecked = updatedMonthFeeDetails.every(
      (item) => !item.isChecked
    );
    if (allUnchecked) {
      setChargeDetails([]);
    }

    let newTotalAmount = 0;
    let newTotalFine = 0;
    updatedMonthFeeDetails.forEach((item) => {
      if (item.isChecked) {
        newTotalAmount += item.totalFee + item.fine - item.paidAmount;
        newTotalFine += item.fine;
      }
    });
    setTotalAmount(newTotalAmount);
    setTotalFine(newTotalFine);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setStudentDetails({
      ...studentDetails,
      [name]: value,
    });
  };

  useEffect(() => {
    fetchClass();
    fetchBank();
    fetchSessions();
  }, []);

  const formatDateForAPI = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const formatDateForInput = (dateString) => {
    const [day, month, year] = dateString.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  };

  const handleDateChange = async (index, newDate) => {
    const formattedDate = formatDateForAPI(newDate);
    const updatedMonthFeeDetails = [...monthFeeDetails];
    updatedMonthFeeDetails[index].date = formattedDate;
    setMonthFeeDetails(updatedMonthFeeDetails);

    try {
      const token = sessionStorage.getItem("token");
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/FeeDeposit/FetchFineAmount`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          studentId: selectedStudentId,
          monthId: updatedMonthFeeDetails[index].monthId,
          recieptDate: formattedDate,
        }),
      });
      if (!response.ok) {
        throw new Error(`Error fetching fine amount: ${response.status}`);
      }
      const data = await response.json();
      updatedMonthFeeDetails[index].fine = data[0].fineAmount;
      setMonthFeeDetails(updatedMonthFeeDetails);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchFineAmount = async (studentId, monthId, newDate) => {
    try {
      const token = sessionStorage.getItem("token");
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/FeeDeposit/FetchFineAmount`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          studentId: studentId,
          monthId: monthId,
          recieptDate: newDate,
        }),
      });
      if (!response.ok) {
        throw new Error(`Error fetching fine amount: ${response.status}`);
      }
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        throw new Error("Record Not Found");
      }
      return data[0].fineAmount;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };

  const calculateAmountAfterDiscount = () => {
    const total = parseFloat(totalAmount);
    const discount = parseFloat(discountAmount);
    const concession = parseFloat(concessionAmount);

    if (!isNaN(total) && !isNaN(discount) && !isNaN(concession)) {
      const discountedAmount = total - discount - concession;
      setAmountAfterDiscount(discountedAmount.toString());
    } else {
      setAmountAfterDiscount("");
    }
  };

  const handleDiscountChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(inputValue)) {
      setDiscountAmount(inputValue);
    }
  };

  const handleConcessionChange = (event) => {
    setConcessionAmount(event.target.value);
  };

  const handleTotalAmountChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(inputValue)) {
      setTotalAmount(parseFloat(inputValue));
    }
  };

  const handlePaidAmountChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(inputValue)) {
      setPaidAmount(inputValue);
    }
  };

  React.useEffect(() => {
    calculateAmountAfterDiscount();
  }, [totalAmount, discountAmount, concessionAmount]);

  const calculateBalanceAmount = () => {
    const total = parseFloat(totalAmount);
    const discount = parseFloat(discountAmount);
    const concession = parseFloat(concessionAmount);

    if (!isNaN(total) && !isNaN(discount) && !isNaN(concession)) {
      const discountedAmount = total - discount - concession;
      setAmountAfterDiscount(discountedAmount.toString());
    } else {
      setAmountAfterDiscount("");
    }
  };

  const calculateBalance = () => {
    const advance = parseFloat(advanceAmount) || 0;
    const paid = parseFloat(paidAmount) || 0;
    const amountAfterDiscountNum = parseFloat(amountAfterDiscount) || 0;

    let calculatedBalance = amountAfterDiscountNum - advance - paid;
    let extraAmountLocal = 0;

    if (calculatedBalance < 0) {
      extraAmountLocal = Math.abs(calculatedBalance);
      calculatedBalance = 0;
    }

    setBalance(calculatedBalance.toString());
    setExtraAmount(extraAmountLocal);
  };

  const handleAdvanceChange = (event) => {
    setAdvanceAmount(event.target.value);
  };

  const handlePaidChange = (event) => {
    setPaidAmount(event.target.value);
  };

  React.useEffect(() => {
    calculateBalanceAmount();
  }, [totalAmount, discountAmount, concessionAmount]);

  React.useEffect(() => {
    calculateBalance();
  }, [advanceAmount, paidAmount, amountAfterDiscount]);

  const handleSessionChange = (event) => {
    const sessionId = event.target.value;
    setSelectedSession(sessionId);
    if (selectedStudentId) {
      fetchMonthFeeDetails(selectedStudentId, sessionId);
    }
  };

  const handleSave = async () => {
    const selectedMonths = monthFeeDetails.filter((item) => item.isChecked);
    if (selectedMonths.length === 0) {
      setShowMonthDialog(true);
      return;
    }

    if (parseFloat(paidAmount) === 0 || paidAmount === "") {
      setShowPaidAmountDialog(true);
      return;
    }

    const endpoint = isPartialPayment ? "FeeDepositMonthly" : "FeeDeposit";
    const feeCharges = chargeDetails.map((item) => ({
      chargeId: item.chargerId,
      chargerAmount: item.chargerAmount,
    }));

    const sessionId = selectedSession;
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}/${
      currentDate.getMonth() + 1
    }/${currentDate.getFullYear()}`;

    const admissionNo = document.getElementById("admissionNo").value;
    const className = document.getElementById("class").value;
    const studentName = document.getElementById("field4").value;
    const fatherName = document.getElementById("fatherName").value;
    const section = document.getElementById("section").value;
    const totalAmount = document.getElementById("amount").value;
    const paidAmount = document.getElementById("paidamount").value;
    const discountAmount = document.getElementById("discountAmout").value;
    const balance = document.getElementById("Balance").value;
    const paymentMode = document.getElementById("paymentMode").value;
    const concessionAmount = document.getElementById("Concession").value;
    const previousPaidAmount = document.getElementById("prevpaidAmnt").value;

    const payload = {
      walleteAmount: parseFloat(walleteAmount) || 0,
      paidStatus: true,
      feePaymentId: 0,
      studentId: studentId,
      advanceAmount: extraAmount,
      fineAmount: totalFine,
      paidAmount: parseFloat(paidAmount),
      month: "string",
      monthId: 0,
      sessionId: sessionId,
      checqueNo: "",
      checqueDate: "",
      paymentDate: formattedDate,
      paymentMode: selectedPaymentMode.toString(),
      paymentModeName: selectedPaymentModeName || "",
      balance: parseFloat(balance) || 0,
      discount: parseFloat(discountAmount),
      concessionAmount: parseFloat(concessionAmount) || 0,
      previousPaidAmount: prevPaidAmount,
    };

    if (endpoint !== "FeeDeposit") {
      payload.feeCharge = feeCharges;
      payload.monthFee = selectedMonths.map((item) => ({
        feePaymentId: item.feePaymentId,
        isMonthCheck: true,
        totalFee: totalAmount,
        paidAmount: item.paidAmount,
        month: item.monthName,
        fine: item.fine,
        paidStaus: item.paidStaus,
        monthId: item.monthId,
      }));
    }

    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      setLoadingBarProgress(30);
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${apiUrl}/FeeDeposit/${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        setLoadingBarProgress(0);
        alert("Failed to deposit fee");
        setShowMoodal(false);
      } else {
        const data = await response.json();
        const paymentId = data.paymentId;
        handlePrintReceipt(paymentId);
        setShowMoodal(true);

        resetForm();
        setMonthFeeDetails([]);
        setTotalAmount("");
        setPaidAmount("0.00");
        setTotalFine("0.00");
        setLoadingBarProgress(100);
        fetchMonthFeeDetails(studentId, selectedSession);
      }
    } catch (error) {
      console.error("Error occurred while saving data:", error);
    }
  };

  const annualHandleSave = async () => {
    const sessionId = selectedSession;
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}/${
      currentDate.getMonth() + 1
    }/${currentDate.getFullYear()}`;

    const totalAmount = document.getElementById("amount").value;
    const paidAmount = document.getElementById("paidamount").value;
    const discountAmount = document.getElementById("discountAmout").value;
    const balance = document.getElementById("Balance").value;
    const concessionAmount = document.getElementById("Concession").value;
    const paymentMode = document.getElementById("paymentMode").value;
    const previousPaidAmount = document.getElementById("prevpaidAmnt").value;

    const payload = {
      walleteAmount: parseFloat(studentDetails.walleteAmount) || 0,
      feePaymentId: 0,
      studentId: studentId,
      advanceAmount: extraAmount,
      fineAmount: parseFloat(totalFine),
      paidAmount: parseFloat(paidAmount),
      month: "string",
      sessionId: sessionId,
      monthId: 0,
      checqueNo: "0",
      checqueDate: "01/01/2020",
      paymentDate: formattedDate,
      paymentMode: "string",
      paymentModeId: parseInt(paymentMode),
      balance: parseFloat(studentDetails.balance),
      discount: parseFloat(discountAmount),
      concessionAmount: parseFloat(studentDetails.concessionAmount),
      previousPaidAmount: 0,
    };

    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      setLoadingBarProgress(30);
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${apiUrl}/FeeDeposit/FeeDeposit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        setLoadingBarProgress(0);
        alert("Failed to deposit fee");
        setShowMoodal(false);
      } else {
        const data = await response.json();
        const paymentId = data.paymentId;
        handlePrintReceipt(paymentId);
        setShowMoodal(true);
        setLoadingBarProgress(100);
      }

      document.getElementById("amount").value = "";
      document.getElementById("paidamount").value = "";
      document.getElementById("discountAmout").value = "";
      document.getElementById("Balance").value = "";
      document.getElementById("Concession").value = "";
      document.getElementById("advanceamount").value = "";
      document.getElementById("paymentMode").value = "Select";
    } catch (error) {
      console.error("Error occurred while saving data:", error);
    }
  };

  const handlePrint = async (paymentId) => {
    try {
      await handlePrintReceipt(paymentId);
      setShowMoodal(true);
    } catch (error) {
      console.error("Error fetching print data:", error);
    }
  };

  const handlePrintReceipt = async (paymentId) => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${apiUrl}/FeeDeposit/MonthFeeReciept`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          paymentId: paymentId,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setPrintData(data);
      } else {
        console.error("Failed to fetch receipt data");
      }
    } catch (error) {
      console.error("API request error:", error);
    }
  };

  const generatePDF = () => {
    if (!printData) return;

    const schoolName =
      sessionStorage.getItem("organizationName")?.replace(/['"]+/g, "") ||
      "JEEVAN ADARSH VIDYALAYA";
    const schoolAddress =
      sessionStorage.getItem("address")?.replace(/['"]+/g, "") ||
      "STREET NO 1 2 B- BLOCK SARUP VIHAR, SOME OTHER INFO HERE";

    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: [300, 200],
    });

    const lineHeight = 7;
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const padding = 5;
    const receiptWidth = pageWidth / 2 - padding * 2;

    const generateReceipt = (offsetX) => {
      let yPos = 15;
      const boxX = offsetX + padding;
      const boxY = padding;
      const boxWidth = receiptWidth;
      const boxHeight = pageHeight - padding * 2;

      doc.setDrawColor(0);
      doc.setLineWidth(0.5);
      doc.rect(boxX, boxY, boxWidth, boxHeight, "S");

      const logo = sessionStorage.getItem("clientLogo");
      const logoSrc = `https://arizshad-002-site5.ktempurl.com${logo.replace(
        "~",
        ""
      )}`;
      const logoWidth = 20;
      const logoHeight = 20;

      doc.setFontSize(18);
      doc.setTextColor(0);
      doc.addImage(logoSrc, "JPEG", boxX + 5, boxY + 5, logoWidth, logoHeight);
      doc.text(schoolName, boxX + boxWidth / 2, yPos + 5, { align: "center" });

      doc.setFontSize(10);
      const addressLines = doc.splitTextToSize(schoolAddress, boxWidth - 10);
      addressLines.forEach((line, i) => {
        doc.text(line, boxX + boxWidth / 2, yPos + 12 + i * 5, {
          align: "center",
        });
      });

      yPos += lineHeight * 2 + addressLines.length * 5;

      yPos += lineHeight * 0.5;
      doc.setDrawColor(0);
      doc.setLineWidth(0.3);
      doc.line(boxX + 10, yPos, boxX + boxWidth - 10, yPos);

      const receiptDetails = [
        [
          "Name:",
          printData.list[0].studentName,
          "Date:",
          printData.list[0].feePaymentDate,
        ],
        [
          "Class:",
          printData.list[0].class,
          "Admission No:",
          printData.list[0].admissionNo,
        ],
        [
          "Month:",
          printData.list[0].month || "N/A",
          "Receipt No:",
          printData.list[0].recieptNo,
        ],
      ];

      doc.autoTable({
        startY: yPos + 3,
        body: receiptDetails,
        columnStyles: {
          0: { fontStyle: "bold" },
          2: { halign: "right", fontStyle: "bold" },
        },
        margin: { left: boxX + 5 },
        tableWidth: boxWidth - 10,
      });

      yPos = doc.autoTable.previous.finalY + 5;

      const tableData = [["Fee Particulars", "Amount"]];
      printData.chargerList.forEach((charge) => {
        tableData.push([charge.chargeName, charge.chargeAmount.toString()]);
      });
      tableData.push(["Total:", printData.totalChargeAmount.toString()]);
      tableData.push([
        "Discount Amount:",
        printData.discountAmount?.toString() || "0",
      ]);

      doc.autoTable({
        startY: yPos,
        head: [
          [
            "Fee Particulars",
            { content: "Amount", styles: { halign: "right" } },
          ],
        ],
        body: tableData.slice(1),
        columnStyles: {
          0: { fontStyle: "bold" },
          1: { halign: "right", cellPadding: { top: 2, right: 3 } },
        },
        headStyles: { fillColor: [160, 160, 160] },
        margin: { left: boxX + 5 },
        tableWidth: boxWidth - 10,
      });

      yPos = doc.autoTable.previous.finalY + 5;

      const paymentBoxX = boxX + 3;
      const paymentBoxY = yPos;
      const paymentBoxWidth = boxWidth - 10;
      const paymentBoxHeight = 50;

      doc.setDrawColor(0);
      doc.setLineWidth(0.3);
      doc.rect(paymentBoxX, paymentBoxY, paymentBoxWidth, paymentBoxHeight);

      const paymentDetails = [
        ["Total Charge Amount:", printData.totalChargeAmount.toString()],
        ["Paid Amount:", printData.list[0].paidAmount.toString()],
        [
          "Previous Paid Amount:",
          printData.list[0].previousPaidAmount?.toString() || "0",
        ],
        ["Fine Amount:", printData.list[0].fineAmount.toString()],
        ["Wallet Amount:", printData.list[0].walletAmount.toString()],
        ["Balance:", printData.list[0].balance.toString()],
      ];

      doc.autoTable({
        startY: paymentBoxY + 3,
        body: paymentDetails,
        columnStyles: {
          0: { fontStyle: "bold" },
          1: { halign: "right", fontStyle: "bold" },
        },
        margin: { left: boxX + 5 },
        tableWidth: paymentBoxWidth - 5,
      });
    };

    generateReceipt(0);
    generateReceipt(pageWidth / 2);

    const pdfBlob = doc.output("blob");
    const url = window.URL.createObjectURL(pdfBlob);
    window.open(url, "_blank");
    window.URL.revokeObjectURL(url);
  };

  return (
    <Container>
      <div
        style={{
          marginTop: "50px",
          border: "0.3px solid #CC9966",
          height: "50px",
          background: "rgb(190 171 108 / 92%)",
          color: "white",
        }}
      >
        <div className="form-check form-check-inline m-3">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio1"
            value="Partial Payment"
            onChange={handleCheckboxChange}
            checked={isPartialPayment}
          />
          <label className="form-check-label" htmlFor="inlineRadio1">
            <b>Partial Payment</b>
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio2"
            value="Annual Pack Payment"
            onChange={handleCheckboxChange}
            checked={!isPartialPayment}
          />
          <label className="form-check-label" htmlFor="inlineRadio2">
            <b>Annual Pack Payment</b>
          </label>
        </div>
      </div>

      <div className="container mt-3 card">
        <form style={{ marginTop: "10px" }}>
          <div className="row mb-3">
            <div className="col">
              <label htmlFor="admissionNo" className="form-label">
                Admission NO.
              </label>
              <div className="input-group">
                <input
                  style={{ backgroundColor: "#e3e3e3" }}
                  type="text"
                  className="form-control"
                  id="admissionNo"
                  placeholder="Admission NO."
                  value={studentDetails.admissionNo || formValues.admissionNo}
                  readOnly
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  id="button-addon2"
                  onClick={fetchAdmission}
                >
                  🔍
                </button>
              </div>
            </div>
            <div className="col">
              <label htmlFor="receiptDate" className="form-label">
                Receipt Date
              </label>
              <input
                type="date"
                className="form-control"
                id="receiptDate"
                placeholder="Receipt Date"
                value={studentDetails.receiptDate}
                onChange={handleInputChange}
              />
            </div>
            <div className="col">
              <label htmlFor="class" className="form-label">
                Class
              </label>
              <input
                style={{ backgroundColor: "#e3e3e3" }}
                type="text"
                className="form-control"
                id="class"
                placeholder="Class"
                value={studentDetails.className || formValues.className}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <label htmlFor="field4" className="form-label">
                Student Name
              </label>
              <input
                style={{ backgroundColor: "#e3e3e3" }}
                type="text"
                className="form-control"
                id="field4"
                placeholder="Student Name"
                value={studentDetails.studentName || formValues.studentName}
                onChange={handleInputChange}
              />
            </div>
            <div className="col">
              <label htmlFor="fatherName" className="form-label">
                Father Name
              </label>
              <input
                style={{ backgroundColor: "#e3e3e3" }}
                type="text"
                className="form-control"
                id="fatherName"
                placeholder="Father Name"
                value={studentDetails.fatherName || formValues.fatherName}
                onChange={handleInputChange}
              />
            </div>
            <div className="col">
              <label htmlFor="section" className="form-label">
                Section
              </label>
              <input
                style={{ backgroundColor: "#e3e3e3" }}
                type="text"
                className="form-control"
                id="section"
                placeholder="Section"
                value={studentDetails.sectionName || formValues.sectionName}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </form>
      </div>

      <div className="container mt-3">
        <div className="row">
          <div className="col-6" style={{ width: "37%" }}>
            <div className="card">
              <div className="card-body">
                <input
                  className="form-control"
                  type="text"
                  value={
                    chargeDetails.length === 0
                      ? "No record to show"
                      : "Charge Details"
                  }
                  aria-label="Disabled input example"
                  style={{ height: "50px", marginTop: "15px" }}
                  disabled
                  readOnly
                />
                {chargeDetails.length !== 0 && (
                  <div className="mt-3">
                    <table className="table">
                      <thead
                        style={{ backgroundColor: "#1898c2eb", color: "white" }}
                      >
                        <tr>
                          <th>Serial No</th>
                          <th>Charge Name</th>
                          <th>Charge Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {chargeDetails.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.chargeName}</td>
                            <td>
                              <input
                                type="number"
                                className="form-control"
                                value={item.chargerAmount}
                                onChange={(e) =>
                                  handleAmountChange(index, e.target.value)
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
                  <DialogTitle>Invalid Amount</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      The entered amount cannot be less than the paid amount.
                      Please check your input.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </div>
          <div className="col-6" style={{ width: "63%" }}>
            <div className="card">
              <h5
                className="card-title"
                style={{
                  border: "0.1px solid #1898c2eb",
                  height: "30px",
                  width: "100%",
                  background: "#1898c2eb",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Payment For Month
              </h5>
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-4">
                    <label htmlFor="sessionDropdown" className="form-label">
                      Financial Year
                    </label>
                    <select
                      id="sessionDropdown"
                      className="form-select"
                      value={selectedSession}
                      onChange={handleSessionChange}
                    >
                      <option value="" disabled>
                        Select Session
                      </option>
                      {sessions.map((session) => (
                        <option
                          key={session.financialYearID}
                          value={session.financialYearID}
                        >
                          {session.finanacialYear}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10">
                    <div className="container" style={{ width: "42vw" }}>
                      {noDataFound ? (
                        <p style={{ textAlign: "center" }}>No Record Found</p>
                      ) : (
                        <table
                          className="table table-bordered table-responsive"
                          style={{ fontSize: "12px", overflowX: "auto" }}
                        >
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">First Date</th>
                              <th scope="col">Month</th>
                              <th scope="col">Fee Amount</th>
                              <th scope="col">Fine</th>
                              <th scope="col">Paid Amount</th>
                              <th scope="col">Status</th>
                              <th scope="col">Print</th>
                            </tr>
                          </thead>
                          <tbody>
                            {monthFeeDetails.map((item, index) => (
                              <tr
                                key={index}
                                className={
                                  item.paidStaus === 1 ? "highlighted-row" : ""
                                }
                              >
                                <th scope="row">
                                  <input
                                    className="form-check-input position-static"
                                    type="checkbox"
                                    id={`blankCheckbox${index}`}
                                    checked={item.isChecked || false}
                                    onChange={(e) =>
                                      handleCheckboxesChange(
                                        index,
                                        e.target.checked
                                      )
                                    }
                                    disabled={item.paidStaus === 1}
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      border: "2px solid black",
                                      accentColor: "black",
                                      cursor:
                                        item.paidStaus === 1
                                          ? "not-allowed"
                                          : "pointer",
                                    }}
                                  />
                                </th>
                                <td>
                                  <input
                                    type="date"
                                    className="form-control"
                                    id={`exampleInputEmail1${index}`}
                                    aria-describedby="enterDate"
                                    placeholder="Enter Date"
                                    value={formatDateForInput(item.date)}
                                    onChange={(e) =>
                                      handleDateChange(index, e.target.value)
                                    }
                                    style={{ width: "110px" }}
                                  />
                                </td>
                                <td>{item.monthName}</td>
                                <td>{item.totalFee}</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id={`input2${index}`}
                                    value={item.fine}
                                    style={{ width: "70px" }}
                                  />
                                </td>
                                <td id="prevpaidAmnt">{item.paidAmount}</td>
                                <td>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color:
                                        item.paidStaus === 1 ? "green" : "red",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {item.paidStaus === 1 ? "Paid" : "Unpaid"}
                                  </span>
                                </td>
                                <td>
                                  <button
                                    className="btn btn-primary"
                                    disabled={item.transactionId === 0}
                                    onClick={() =>
                                      handlePrint(item.transactionId)
                                    }
                                  >
                                    Print
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isPartialPayment ? (
        <div className="container card mt-2 py-3">
          <form>
            <div className="row mb-3">
              <div className="col-sm-4">
                <label htmlFor="field1">Total Amount</label>
                <input
                  type="text"
                  className="form-control"
                  id="amount"
                  placeholder="Amount"
                  value={totalAmount ?? formValues.totalAmount ?? ""}
                  onChange={handleTotalAmountChange}
                  readOnly
                />
              </div>
              <div className="col-sm-4">
                <label htmlFor="field2">Paid Amount</label>
                <input
                  type="text"
                  className="form-control"
                  id="paidamount"
                  placeholder="Paid Amount"
                  value={paidAmount || formValues.paidAmount}
                  onChange={handlePaidAmountChange}
                />
              </div>
              <div className="mb-3 col">
                <label htmlFor="paymentMode">Payment Mode</label>
                <select
                  id="paymentMode"
                  className="form-select"
                  value={selectedPaymentMode}
                  onChange={handlePaymentModeChange}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {bankData.map((item) => (
                    <option key={item.bankId} value={item.bankId}>
                      {item.bankName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row mb-3" style={{ marginTop: "-20px" }}>
              <div className="col">
                <label htmlFor="field4">Discount Amount</label>
                <input
                  type="text"
                  className="form-control"
                  id="discountAmout"
                  placeholder="Discount Amount"
                  value={discountAmount || formValues.discountAmount}
                  onChange={handleDiscountChange}
                />
              </div>
              <div className="col">
                <label htmlFor="field5">Balance</label>
                <input
                  style={{ backgroundColor: "#e3e3e3" }}
                  type="text"
                  className="form-control"
                  id="Balance"
                  placeholder="Balance"
                  value={balance || formValues.balance}
                  readOnly
                />
              </div>
              <div className="col">
                <label htmlFor="field9">Inventory Balance</label>
                <input
                  style={{ backgroundColor: "#e3e3e3" }}
                  type="text"
                  className="form-control"
                  id="inventoryBalance"
                  placeholder="Inventory Balance"
                  readOnly
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="field7">Concession</label>
                <input
                  style={{ backgroundColor: "#e3e3e3" }}
                  type="text"
                  className="form-control"
                  id="Concession"
                  placeholder="Concession"
                  value={concessionAmount || formValues.concessionAmount}
                  readOnly
                />
              </div>
              <div className="col">
                <label htmlFor="field8">Fine</label>
                <input
                  style={{ backgroundColor: "#e3e3e3" }}
                  type="text"
                  className="form-control"
                  id="fine"
                  placeholder="fine"
                  value={totalFine}
                  readOnly
                />
              </div>
              <div className="col"></div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="field7">Amount After Discount</label>
                <input
                  style={{ backgroundColor: "#e3e3e3" }}
                  type="text"
                  className="form-control"
                  id="amntafterdiscount"
                  placeholder="Discounted Amount"
                  value={amountAfterDiscount}
                  readOnly
                />
              </div>
              <div className="col">
                <label htmlFor="field8">Wallet Amount</label>
                <input
                  type="text"
                  className="form-control"
                  id="walletAmount"
                  placeholder="Wallet Amount"
                  value={walleteAmount}
                  style={{ backgroundColor: "#e3e3e3" }}
                />
              </div>
              <div className="col"></div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="field7">Advance Amount</label>
                <input
                  style={{ backgroundColor: "#e3e3e3" }}
                  type="text"
                  className="form-control"
                  id="advanceamount"
                  placeholder="Advance Amount"
                  value={advanceAmount}
                  onChange={(e) => setAdvanceAmount(e.target.value)}
                  readOnly
                />
              </div>
              <div className="col"></div>
              <div className="col"></div>
            </div>
          </form>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              type="button"
              className="btn btn-success my-2"
              onClick={handleSave}
            >
              <b>SAVE</b>
            </button>
            <button
              type="button"
              className="btn btn-warning my-2"
              style={{ marginLeft: "25px" }}
              onClick={() => window.location.reload()}
            >
              <b>RESET</b>
            </button>
          </div>
          <Dialog open={showMonthDialog} onClose={handleCloseMonthDialog}>
            <DialogTitle>Validation Error</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please select at least one month for payment.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseMonthDialog} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={showPaidAmountDialog}
            onClose={handleClosePaidAmountDialog}
          >
            <DialogTitle>Validation Error</DialogTitle>
            <DialogContent>
              <DialogContentText>
                The paid amount cannot be zero. Please enter a valid amount.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosePaidAmountDialog} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <div className="container card mt-2 py-3">
          <form>
            <div className="row mb-3">
              <div className="col-sm-4">
                <label htmlFor="field1">Total Amount</label>
                <input
                  type="text"
                  className="form-control"
                  id="amount"
                  placeholder="Amount"
                  value={studentDetails.totalFee}
                  onChange={handleTotalAmountChange}
                  readOnly
                />
              </div>
              <div className="col-sm-4">
                <label htmlFor="field2">Paid Amount</label>
                <input
                  type="text"
                  className="form-control"
                  id="paidamount"
                  placeholder="Paid Amount"
                  value={paidAmount}
                  onChange={handlePaidAmountChange}
                />
              </div>
              <div className="mb-3 col">
                <label htmlFor="paymentMode">Payment Mode</label>
                <select
                  id="paymentMode"
                  className="form-select"
                  value={selectedPaymentMode}
                  onChange={handlePaymentModeChange}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {bankData.map((item) => (
                    <option key={item.bankId} value={item.bankId}>
                      {item.bankName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row mb-3" style={{ marginTop: "-20px" }}>
              <div className="col">
                <label htmlFor="field4">Discount Amount</label>
                <input
                  type="text"
                  className="form-control"
                  id="discountAmout"
                  placeholder="Discount Amount"
                  value={discountAmount}
                  onChange={handleDiscountChange}
                />
              </div>
              <div className="col">
                <label htmlFor="field5">Balance</label>
                <input
                  style={{ backgroundColor: "#e3e3e3" }}
                  type="text"
                  className="form-control"
                  id="Balance"
                  placeholder="Balance"
                  value={studentDetails.balance}
                  readOnly
                />
              </div>
              <div className="col">
                <label htmlFor="field9">Inventory Balance</label>
                <input
                  style={{ backgroundColor: "#e3e3e3" }}
                  type="text"
                  className="form-control"
                  id="inventoryBalance"
                  placeholder="Inventory Balance"
                  value={studentDetails.inveintoryBalance}
                  readOnly
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="field7">Concession</label>
                <input
                  style={{ backgroundColor: "#e3e3e3" }}
                  type="text"
                  className="form-control"
                  id="Concession"
                  placeholder="Concession"
                  value={studentDetails.concessionAmount}
                  readOnly
                />
              </div>
              <div className="col">
                <label htmlFor="field8">Fine</label>
                <input
                  style={{ backgroundColor: "#e3e3e3" }}
                  type="text"
                  className="form-control"
                  id="fine"
                  placeholder="fine"
                  value={totalFine}
                  readOnly
                />
              </div>
              <div className="col"></div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="field7">Amount After Discount</label>
                <input
                  style={{ backgroundColor: "#e3e3e3" }}
                  type="text"
                  className="form-control"
                  id="amntafterdiscount"
                  placeholder="Discounted Amount"
                  value={amountAfterDiscount}
                  readOnly
                />
              </div>
              <div className="col">
                <label htmlFor="field8">Wallet Amount</label>
                <input
                  type="text"
                  className="form-control"
                  id="walletAmount"
                  placeholder="Wallet Amount"
                  value={studentDetails.walletAmount}
                  style={{ backgroundColor: "#e3e3e3" }}
                />
              </div>
              <div className="col"></div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="field7">Advance Amount</label>
                <input
                  type="text"
                  className="form-control"
                  id="advanceamount"
                  placeholder="Advance Amount"
                  value={advanceAmount}
                  onChange={(e) => setAdvanceAmount(e.target.value)}
                />
              </div>
              <div className="col"></div>
              <div className="col"></div>
            </div>
          </form>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              type="button"
              className="btn btn-success my-2"
              onClick={annualHandleSave}
            >
              <b>SAVE</b>
            </button>
            <button
              type="button"
              className="btn btn-warning my-2"
              style={{ marginLeft: "25px" }}
              onClick={() => window.location.reload()}
            >
              <b>RESET</b>
            </button>
          </div>
        </div>
      )}

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>Admission Data</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}
        >
          <div
            className="navbar mb-3"
            style={{ backgroundColor: "#1898c2eb", padding: "10px" }}
          >
            <div className="input-group" style={{ width: "45%" }}>
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <div className="input-group" style={{ width: "45%" }}>
              <select
                className="form-select"
                value={selectedClass}
                onChange={handleClassChange}
              >
                <option value="">Select Class</option>
                {ddlClass.map((item) => (
                  <option key={item.classId} value={item.classId}>
                    {item.className}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {noDataFound ? (
            <p style={{ textAlign: "center" }}>No data found</p>
          ) : (
            <table
              className="table"
              style={{ fontSize: "13px", fontWeight: "bold" }}
            >
              <thead>
                <tr>
                  <th>Admission No.</th>
                  <th>Student Name</th>
                  <th>Class</th>
                  <th>Section</th>
                  <th>Father's Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.admissionNo}</td>
                    <td>{item.studentName}</td>
                    <td>{item.className}</td>
                    <td>{item.sectionName}</td>
                    <td>{item.fatherName}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-success my-2 btn-sm"
                        onClick={() => handleSelectButtonClick(item.studentId)}
                      >
                        <b>Select</b>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showMoodal} onHide={() => setShowMoodal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>
            Fee Deposit Successful
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your fee deposit was successful.</p>
          <p>Click the button below to generate the receipt.</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={generatePDF}>
            Generate PDF
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default FeeDeposit;
