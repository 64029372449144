import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Select,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControlLabel,
  Checkbox,
  Box,
  TextField,
  TablePagination,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

function ClassPromotion() {
  const [selectedClass, setSelectedClass] = useState("lightOptionValue");
  const [selectedMode, setSelectedMode] = useState("lightOptionValue");
  const [years, setYears] = useState([]);
  const [ddlClass, setDdlClass] = useState([]);
  const [ddlSection, setDdlSection] = useState([]);
  const [selectedFromClass, setSelectedFromClass] =
    useState("lightOptionValue");
  const [ddlPromoteClass, setDdlPromoteClass] = useState([]);
  const [ddlToSection, setDdlToSection] = useState([]);
  const [selectedSession, setSelectedSession] = useState("");
  const [studentData, setStudentData] = useState([]);
  const [recordNotFound, setRecordNotFound] = useState(false);
  const [toselectedSession, setToSelectedSession] = useState("");
  const [toSelectedSection, setToSelectedSection] = useState("0");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  const fetchYear = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const response = await fetch(
        `${apiUrl}/ClassPromotion/GetFinancialYear`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({}),
        }
      );
      if (!response.ok)
        throw new Error(`Error fetching financial years: ${response.status}`);
      const data = await response.json();
      setYears(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDdlClass = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${apiUrl}/Enquiry/ddlClassName`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({}),
      });
      if (!response.ok)
        throw new Error(`Error fetching classes: ${response.status}`);
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") return;
      setDdlClass(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDdlSection = async () => {
    try {
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/ClassPromotion/ddlSection_clsId`;
      const token = sessionStorage.getItem("token");
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ teacherId: 0, classId: selectedFromClass }),
      });
      if (!response.ok)
        throw new Error(`Error fetching sections: ${response.status}`);
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") return;
      setDdlSection(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDdlPromoteClass = async () => {
    try {
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/ClassPromotion/ddlPromoteClass`;
      const token = sessionStorage.getItem("token");
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ classId: selectedFromClass }),
      });
      if (!response.ok)
        throw new Error(`Error fetching promoted classes: ${response.status}`);
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") return;
      setDdlPromoteClass(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDdlToSection = async () => {
    try {
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/ClassPromotion/ddlSection_clsId`;
      const token = sessionStorage.getItem("token");
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ teacherId: 0, classId: selectedClass }),
      });
      if (!response.ok)
        throw new Error(`Error fetching sections: ${response.status}`);
      const data = await response.json();
      setDdlToSection(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClassChange = (event) => setSelectedClass(event.target.value);
  const handleModeChange = (event) => setSelectedMode(event.target.value);
  const handleSectionChange = (event) =>
    setToSelectedSection(event.target.value);
  const handleToSessionChange = (event) =>
    setToSelectedSession(event.target.value);
  const handleFromClassChange = (event) =>
    setSelectedFromClass(event.target.value);

  const handleSessionChange = (event) => {
    const newSession = event.target.value;
    setSelectedSession(newSession);
    const selectedYear = years.find(
      (year) => year.financialYearID === newSession
    );
    if (selectedYear) {
      const currentYearNum = parseInt(
        selectedYear.finanacialYear.split("-")[0],
        10
      );
      const nextYear = currentYearNum + 1;
      const nextSession = years.find(
        (year) => year.finanacialYear === `${nextYear}-${nextYear + 1}`
      );
      setToSelectedSession(nextSession ? nextSession.financialYearID : "");
    }
  };

  const handleGetStudents = async () => {
    setLoading(true);
    try {
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/ClassPromotion/FetchStudentById`;
      const token = sessionStorage.getItem("token");
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          classId: selectedFromClass,
          sectionId: selectedMode,
          sessionId: selectedSession,
        }),
      });
      if (!response.ok)
        throw new Error(`Error fetching students: ${response.status}`);
      const responseData = await response.json();
      if (
        responseData?.data === null &&
        responseData.msg === "Record Not Found"
      ) {
        alert("No record found");
        setRecordNotFound(true);
        setStudentData([]);
      } else {
        setStudentData(
          responseData.map((student) => ({ ...student, checked: false }))
        );
        setRecordNotFound(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveButtonClick = async () => {
    try {
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/ClassPromotion/ClassTransfer`;
      const token = sessionStorage.getItem("token");
      const checkedStudents = studentData.filter((student) => student.checked);
      const payload = checkedStudents.map((student) => ({
        studentId: student.studentId,
        fromClassId: selectedFromClass,
        fromSectionId: selectedMode,
        toClassId: selectedClass,
        toSectionId: toSelectedSection,
        fromSessionId: selectedSession,
        toSessionId: toselectedSession,
      }));
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok)
        throw new Error(`Error saving data: ${response.status}`);
      const responseData = await response.json();
      alert(
        responseData?.status === null && responseData.msg === "Failed"
          ? "Failed To Save"
          : "Data saved successfully!"
      );
    } catch (error) {
      console.error(error);
      alert("Error saving data. Please try again later.");
    }
  };

  const handleResetButtonClick = () => {
    setStudentData(
      studentData.map((student) => ({ ...student, checked: false }))
    );
    setSelectAll(false);
  };

  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    setStudentData((prevData) =>
      prevData.map((student) => ({ ...student, checked }))
    );
  };

  const handleStudentCheckboxChange = (studentId) => (event) => {
    const checked = event.target.checked;
    setStudentData((prevData) =>
      prevData.map((student) =>
        student.studentId === studentId ? { ...student, checked } : student
      )
    );
    setSelectAll(
      filteredStudents.every((student) => student.checked) &&
        filteredStudents.length > 0
    );
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchYear();
    fetchDdlClass();
  }, []);

  useEffect(() => {
    if (years.length > 0) {
      const sortedYears = [...years].sort(
        (a, b) => b.financialYearID - a.financialYearID
      );
      const latestYear = sortedYears[0];
      const latestYearNum = parseInt(
        latestYear.finanacialYear.split("-")[0],
        10
      );
      const currentSession = years.find(
        (year) =>
          year.finanacialYear === `${latestYearNum - 1}-${latestYearNum}`
      );
      const promotedSession = years.find(
        (year) =>
          year.finanacialYear === `${latestYearNum + 1}-${latestYearNum + 2}`
      );
      if (currentSession) setSelectedSession(currentSession.financialYearID);
      setToSelectedSession(
        promotedSession
          ? promotedSession.financialYearID
          : latestYear.financialYearID
      );
    }
  }, [years]);

  useEffect(() => {
    if (selectedFromClass !== "lightOptionValue") {
      fetchDdlSection();
      fetchDdlPromoteClass();
    }
  }, [selectedFromClass]);

  useEffect(() => {
    if (selectedClass !== "lightOptionValue") fetchDdlToSection();
  }, [selectedClass]);

  const getPromotedSessionOptions = () => {
    if (!selectedSession || selectedSession === "lightOptionValue")
      return years;
    const currentYearObj = years.find(
      (year) => year.financialYearID === selectedSession
    );
    if (!currentYearObj) return [];
    const currentYearNum = parseInt(
      currentYearObj.finanacialYear.split("-")[0],
      10
    );
    const nextYear = currentYearNum + 1;
    return years.filter(
      (year) => year.finanacialYear === `${nextYear}-${nextYear + 1}`
    );
  };

  const filteredStudents = useMemo(() => {
    if (!searchQuery.trim()) return studentData; // Return all if search is empty
    const query = searchQuery.trim().toLowerCase();
    return studentData.filter((student) => {
      const nameMatch = student.studentName?.toLowerCase().includes(query);
      const admissionMatch = student.admissionNo
        ?.toString()
        .toLowerCase()
        .includes(query);
      return nameMatch || admissionMatch;
    });
  }, [studentData, searchQuery]);

  const paginatedStudents = useMemo(() => {
    const start = page * rowsPerPage;
    return filteredStudents.slice(start, start + rowsPerPage);
  }, [filteredStudents, page, rowsPerPage]);

  return (
    <div>
      <Container sx={{ marginTop: 6 }}>
        <Grid container spacing={2} mt={1} marginLeft={1}>
          <AppBar position="static" style={{ backgroundColor: "#0B1F3D" }}>
            <Toolbar>
              <Typography variant="h6" component="div">
                Class Promotion
              </Typography>
            </Toolbar>
          </AppBar>
          <Grid container spacing={2} mt={1} marginLeft={5}>
            <Grid item xs={5}>
              <label htmlFor="mode" className="form-label">
                From Session
              </label>
              <Select
                value={selectedSession}
                onChange={handleSessionChange}
                fullWidth
              >
                <MenuItem value="lightOptionValue">Select Year</MenuItem>
                {years.map((item) => (
                  <MenuItem
                    value={item.financialYearID}
                    key={item.financialYearID}
                  >
                    {item.finanacialYear}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={5}>
              <label htmlFor="class" className="form-label">
                To Session
              </label>
              <Select
                id="class"
                value={toselectedSession}
                onChange={handleToSessionChange}
                fullWidth
              >
                <MenuItem value="lightOptionValue">Select Year</MenuItem>
                {getPromotedSessionOptions().map((item) => (
                  <MenuItem
                    value={item.financialYearID}
                    key={item.financialYearID}
                  >
                    {item.finanacialYear}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1} marginLeft={5}>
            <Grid item xs={5}>
              <label htmlFor="fromClass" className="form-label">
                From Class
              </label>
              <Select
                id="fromClass"
                value={selectedFromClass}
                onChange={handleFromClassChange}
                fullWidth
              >
                <MenuItem value="lightOptionValue">Select Class</MenuItem>
                {ddlClass.map((classItem) => (
                  <MenuItem key={classItem.classId} value={classItem.classId}>
                    {classItem.className}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={5}>
              <label htmlFor="toClass" className="form-label">
                To Class
              </label>
              <Select
                id="toClass"
                value={selectedClass}
                onChange={handleClassChange}
                fullWidth
              >
                <MenuItem value="lightOptionValue">Select Class</MenuItem>
                {ddlPromoteClass.map((classItem) => (
                  <MenuItem key={classItem.classId} value={classItem.classId}>
                    {classItem.className}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1} marginLeft={5}>
            <Grid item xs={5}>
              <label htmlFor="fromSection" className="form-label">
                From Section
              </label>
              <Select
                id="toSection"
                value={selectedMode}
                onChange={handleModeChange}
                fullWidth
              >
                <MenuItem value="lightOptionValue">Select Section</MenuItem>
                {ddlSection.map((section) => (
                  <MenuItem key={section.sectionId} value={section.sectionId}>
                    {section.sectionName}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={5}>
              <label htmlFor="toSection" className="form-label">
                To Section
              </label>
              <Select
                id="fromSection"
                value={toSelectedSection}
                onChange={handleSectionChange}
                fullWidth
              >
                <MenuItem value="0">Select Section</MenuItem>
                {ddlToSection.map((section) => (
                  <MenuItem key={section.sectionId} value={section.sectionId}>
                    {section.sectionName}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid container justifyContent="center" mt={3}>
              <Grid item xs={6}>
                <Button
                  type="button"
                  variant="contained"
                  color="warning"
                  onClick={handleGetStudents}
                  sx={{ marginTop: 3, marginLeft: "auto", marginRight: "auto" }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : "Get Students"}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {recordNotFound && (
            <Box sx={{ marginTop: 2 }}>
              <Typography variant="body1" color="error">
                Record Not Found
              </Typography>
            </Box>
          )}

          {studentData.length > 0 && (
            <>
              <AppBar
                position="static"
                sx={{ marginTop: 2 }}
                style={{ backgroundColor: "#0B1F3D" }}
              >
                <Toolbar>
                  <Typography variant="h6" component="div">
                    Student List
                  </Typography>
                </Toolbar>
              </AppBar>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 2,
                }}
              >
                <TextField
                  variant="outlined"
                  placeholder="Enter Name or Admission no."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  sx={{
                    width: "100%",
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#0B1F3D",
                      },
                      "&:hover fieldset": {
                        borderColor: "#0B1F3D",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#0B1F3D",
                      },
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#0B1F3D" }} />
                      </InputAdornment>
                    ),
                    sx: {
                      height: "45px",
                      fontSize: "16px",
                      color: "#333",
                      paddingRight: "10px",
                    },
                  }}
                />
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                  }
                  label="Select All"
                  sx={{ color: "#0B1F3D" }}
                /> */}
              </Box>
              <TableContainer
                component={Paper}
                sx={{ marginTop: 2, maxHeight: 400 }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ backgroundColor: "#f5f5f5" }}>
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAllChange}
                        />
                      </TableCell>
                      <TableCell
                        sx={{ backgroundColor: "#f5f5f5" }}
                        className="text-primary"
                      >
                        <b>Admission No.</b>
                      </TableCell>
                      <TableCell
                        sx={{ backgroundColor: "#f5f5f5" }}
                        className="text-primary"
                      >
                        <b>Student Name</b>
                      </TableCell>
                      <TableCell
                        sx={{ backgroundColor: "#f5f5f5" }}
                        className="text-primary"
                      >
                        <b>Roll-No.</b>
                      </TableCell>
                      <TableCell
                        sx={{ backgroundColor: "#f5f5f5" }}
                        className="text-primary"
                      >
                        <b>Class</b>
                      </TableCell>
                      <TableCell
                        sx={{ backgroundColor: "#f5f5f5" }}
                        className="text-primary"
                      >
                        <b>Section</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : paginatedStudents.length > 0 ? (
                      paginatedStudents.map((item) => (
                        <TableRow key={item.studentId} hover>
                          <TableCell>
                            <Checkbox
                              checked={item.checked || false}
                              onChange={handleStudentCheckboxChange(
                                item.studentId
                              )}
                            />
                          </TableCell>
                          <TableCell>{item.admissionNo}</TableCell>
                          <TableCell>{item.studentName}</TableCell>
                          <TableCell>{item.rollNo}</TableCell>
                          <TableCell>{item.className}</TableCell>
                          <TableCell>{item.section}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No matching students found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={filteredStudents.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ marginTop: 2 }}
              />
              <Grid item xs={6}>
                <Button
                  type="button"
                  variant="contained"
                  color="success"
                  sx={{ marginTop: 3, marginLeft: 10 }}
                  onClick={handleSaveButtonClick}
                  disabled={loading}
                >
                  Save
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  color="warning"
                  sx={{ marginTop: 3, marginLeft: 2 }}
                  onClick={handleResetButtonClick}
                  disabled={loading}
                >
                  Reset
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
}

export default ClassPromotion;
