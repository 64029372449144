import {
  Container,
  Modal,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Pagination,
  Paper,
  InputAdornment,
  IconButton,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import React, { useState, useEffect } from "react";
import { FaEye } from "react-icons/fa";

function EnquiryFollow() {
  const [followUpType, setFollowUpType] = useState("");
  const [showDateFields, setShowDateFields] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [enquiryData, setEnquiryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState(null);
  const [assignedToOptions, setAssignedToOptions] = useState([]);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [isSearchPerformed, setIsSearchPerformed] = useState(false);
  const [loading, setLoading] = useState(false); // New state for loading

  const statusOptions = [
    "Super Hot",
    "Very Hot",
    "Hot",
    "Cold",
    "Very Cold",
    "Lost",
    "Converted",
  ];

  useEffect(() => {
    fetchAssignedToOptions();
  }, []);

  useEffect(() => {
    if (showDateFields) {
      const today = new Date();
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(today.getMonth() - 1);
      const formatDate = (date) => date.toISOString().split("T")[0];
      setToDate(formatDate(today));
      setFromDate(formatDate(oneMonthAgo));
    }
  }, [showDateFields]);

  useEffect(() => {
    if (followUpType && followUpType !== "SelectDate") {
      handleSearch();
    }
  }, [followUpType]);

  useEffect(() => {
    const filtered = enquiryData.filter((enquiry) =>
      [
        enquiry.parent_GaurdianName?.toLowerCase() || "",
        enquiry.mobile?.toLowerCase() || "",
        enquiry.enquiryCode?.toLowerCase() || "",
      ].some((field) => field.includes(searchTerm.toLowerCase()))
    );
    setFilteredData(filtered);
    setPage(1);
  }, [searchTerm, enquiryData]);

  const formatDateForFetch = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    if (isNaN(date)) return "";
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const formatDateForInsert = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    if (isNaN(date)) return "";
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleFollowUpTypeChange = (event) => {
    const selectedType = event.target.value;
    setFollowUpType(selectedType);
    setShowDateFields(selectedType === "SelectDate");
    setEnquiryData([]);
    setFilteredData([]);
    setError(null);
    setSelectAll(false);
    setIsSearchPerformed(false);
    setLoading(false);
  };

  const fetchAssignedToOptions = async () => {
    try {
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/Enquiry/ddlTeacher`;
      const token = sessionStorage.getItem("token");
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({}),
      });
      if (!response.ok) {
        throw new Error(`Error fetching assigned to options: ${response.status}`);
      }
      const data = await response.json();
      setAssignedToOptions(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = async () => {
    setIsSearchPerformed(true);
    setLoading(true); // Start loading
    try {
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/EnquiryFollowUp/FetchFollowUpList`;
      const token = sessionStorage.getItem("token");
      let requestBody = {};

      switch (followUpType) {
        case "SelectDate":
          if (!fromDate || !toDate) {
            setError("Please select both From Date and To Date.");
            setEnquiryData([]);
            setLoading(false);
            return;
          }
          requestBody = {
            ddlOption: followUpType,
            fromDate: formatDateForFetch(fromDate),
            toDate: formatDateForFetch(toDate),
          };
          break;
        case "AllCurrentDate":
        case "PendingFollowUp":
          requestBody = {
            ddlOption: followUpType,
            fromDate: formatDateForFetch(new Date().toISOString().split("T")[0]),
            toDate: formatDateForFetch(new Date().toISOString().split("T")[0]),
          };
          break;
        default:
          requestBody = {
            ddlOption: followUpType,
            fromDate: "",
            toDate: "",
          };
          break;
      }

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        if (response.status === 404) {
          setEnquiryData([]);
          setError("No record found.");
          setLoading(false);
          return;
        }
        throw new Error(`Error fetching follow-up list: ${response.status}`);
      }

      const responseData = await response.json();
      const data = Array.isArray(responseData.data) ? responseData.data : responseData;
      if (data && data.length > 0) {
        setEnquiryData(data.map((item) => ({ ...item, isChecked: false })));
        setError(null);
      } else {
        setEnquiryData([]);
        setError("No data available");
      }
    } catch (error) {
      console.error("Fetch Error:", error);
      setError("Error fetching data. Please try again later.");
      setEnquiryData([]);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleReset = () => {
    setFollowUpType("");
    setShowDateFields(false);
    setFromDate("");
    setToDate("");
    setEnquiryData([]);
    setFilteredData([]);
    setError(null);
    setFormErrors({});
    setSelectAll(false);
    setSearchTerm("");
    setPage(1);
    setIsSearchPerformed(false);
    setLoading(false);
  };

  const handleCheckboxChange = (event, index) => {
    const newData = [...enquiryData];
    newData[index].isChecked = event.target.checked;
    setEnquiryData(newData);
    setSelectAll(newData.every((item) => item.isChecked));
  };

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    const newData = enquiryData.map((item) => ({
      ...item,
      isChecked: isChecked,
    }));
    setEnquiryData(newData);
  };

  const handleDropdownChange = (event, key, index) => {
    const newData = [...enquiryData];
    newData[index][key] = event.target.value;
    setEnquiryData(newData);
  };

  const handleRemarksChange = (event, index) => {
    const newData = [...enquiryData];
    newData[index].remark = event.target.value;
    setEnquiryData(newData);
  };

  const handleNextFollowUpDateChange = (event, index) => {
    const newData = [...enquiryData];
    newData[index].nextFollowUpDate = event.target.value;
    setEnquiryData(newData);
  };

  const handleEyeClick = async (enquiryId) => {
    try {
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/EnquiryFollowUp/FollowUpRecordById`;
      const token = sessionStorage.getItem("token");
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ enquiryId }),
      });
      if (!response.ok) {
        console.error(`Error fetching follow-up record: ${response.status}`);
      }
      const data = await response.json();
      setSelectedEnquiry(data[0]);
      setModalOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const validateForm = () => {
    let errors = {};
    enquiryData.forEach((enquiry, index) => {
      if (enquiry.isChecked) {
        if (!enquiry.trackedThrough) {
          errors[`trackedThrough-${index}`] = "Follow Up is required";
        }
        if (!enquiry.status) {
          errors[`status-${index}`] = "Status is required";
        }
        if (!enquiry.assignedTo) {
          errors[`assignedTo-${index}`] = "Assigned To is required";
        }
        if (!enquiry.nextFollowUpDate) {
          errors[`nextFollowUpDate-${index}`] = "Follow Up Date is required";
        }
      }
    });
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = async () => {
    if (!validateForm()) return;

    try {
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/EnquiryFollowUp/InsertFollowUp`;
      const token = sessionStorage.getItem("token");

      const checkedEnquiryData = enquiryData.filter((enquiry) => enquiry.isChecked);
      const payload = checkedEnquiryData.map((enquiry) => ({
        enquiryDate: formatDateForInsert(enquiry.enquiryDate) || "",
        followUpDate: formatDateForInsert(enquiry.nextFollowUpDate) || "",
        enquieryCode: String(enquiry.enquiryCode || ""),
        parent_GurdianName: enquiry.parent_GaurdianName || "",
        mobileNo: enquiry.mobile || "",
        remark: enquiry.remark || "",
        followUpType: enquiry.trackedThrough || "",
        status: enquiry.status || "",
        enquiryId: Number(enquiry.enquiryId) || 0,
        assignId:
          assignedToOptions.find((opt) => opt.employeeName === enquiry.assignedTo)?.userId || 0,
      }));

      console.log("Save Payload:", JSON.stringify(payload, null, 2));

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("API Error Response:", errorText);
        alert(`Error saving follow-up data: ${response.status} - ${errorText}`);
        return;
      }

      alert("Follow-up data saved successfully!");
      handleSearch();
    } catch (error) {
      console.error("Save Error:", error);
      alert("Error saving follow-up data. Please try again later.");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const paginatedData = filteredData.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  return (
    <Container sx={{ mt: 5 }}>
      <Typography variant="h4" gutterBottom>
        Enquiry Follow-Up
      </Typography>

      <FormControl fullWidth sx={{ mb: 3, maxWidth: 300 }}>
        <InputLabel id="follow-up-type-label">Follow Up Type</InputLabel>
        <Select
          labelId="follow-up-type-label"
          value={followUpType}
          onChange={handleFollowUpTypeChange}
          label="Follow Up Type"
        >
          <MenuItem value="">Select Follow Up Type</MenuItem>
          <MenuItem value="SelectDate">Selected Date Wise</MenuItem>
          <MenuItem value="AllCurrentDate">Current Date</MenuItem>
          <MenuItem value="PendingFollowUp">Pending</MenuItem>
        </Select>
      </FormControl>

      {showDateFields && (
        <Box sx={{ display: "flex", gap: 2, mb: 3, flexWrap: "wrap" }}>
          <TextField
            label="From Date"
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ maxWidth: 200 }}
          />
          <TextField
            label="To Date"
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ maxWidth: 200 }}
          />
          <Button variant="contained" color="primary" onClick={handleSearch}>
            Search
          </Button>
        </Box>
      )}

      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      {loading && (
        <Typography variant="body1" sx={{ mt: 2 }}>
          Loading...
        </Typography>
      )}

      {!loading && enquiryData.length > 0 && (
        <Paper sx={{ p: 2, mt: 2, boxShadow: 3 }}>
           <Box sx={{ mb: 2 }}>
          <TextField
  id="search"
  label="Search"
  variant="outlined"
  InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <IconButton>
          <SearchIcon />
        </IconButton>
      </InputAdornment>
    )
  }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              fullWidth
              sx={{ maxWidth: 300 }}
            />
          </Box>
          <TableContainer sx={{ maxHeight: 400, overflowX: "auto" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "5%" }}>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                  </TableCell>
                  <TableCell sx={{ width: "10%" }}>Enquiry Code</TableCell>
                  <TableCell sx={{ width: "15%" }}>Parent Name</TableCell>
                  <TableCell sx={{ width: "10%" }}>Mobile</TableCell>
                  <TableCell sx={{ width: "10%" }}>Enquiry Date</TableCell>
                  <TableCell sx={{ width: "15%" }}>Follow Up Date</TableCell>
                  <TableCell sx={{ width: "15%" }}>Follow Up</TableCell>
                  <TableCell sx={{ width: "15%" }}>Status</TableCell>
                  <TableCell sx={{ width: "15%" }}>Assigned To</TableCell>
                  <TableCell sx={{ width: "20%" }}>Remarks</TableCell>
                  <TableCell sx={{ width: "5%" }}>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((enquiry, index) => {
                  const globalIndex = (page - 1) * rowsPerPage + index;
                  return (
                    <TableRow key={globalIndex}>
                      <TableCell>
                        <input
                          type="checkbox"
                          checked={enquiry.isChecked || false}
                          onChange={(event) => handleCheckboxChange(event, globalIndex)}
                        />
                      </TableCell>
                      <TableCell>{enquiry.enquiryCode || "N/A"}</TableCell>
                      <TableCell>{enquiry.parent_GaurdianName || "N/A"}</TableCell>
                      <TableCell>{enquiry.mobile || "N/A"}</TableCell>
                      <TableCell>{enquiry.enquiryDate || "N/A"}</TableCell>
                      <TableCell>
                        <TextField
                          type="date"
                          value={enquiry.nextFollowUpDate || ""}
                          onChange={(e) => handleNextFollowUpDateChange(e, globalIndex)}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          error={!!formErrors[`nextFollowUpDate-${globalIndex}`]}
                          helperText={
                            enquiry.isChecked && formErrors[`nextFollowUpDate-${globalIndex}`]
                              ? formErrors[`nextFollowUpDate-${globalIndex}`]
                              : ""
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth error={!!formErrors[`trackedThrough-${globalIndex}`]}>
                          <InputLabel>Follow Up</InputLabel>
                          <Select
                            value={enquiry.trackedThrough || ""}
                            onChange={(event) => handleDropdownChange(event, "trackedThrough", globalIndex)}
                            label="Follow Up"
                          >
                            <MenuItem value="">--Select--</MenuItem>
                            <MenuItem value="phone">Phone</MenuItem>
                            <MenuItem value="email">Email</MenuItem>
                          </Select>
                          {enquiry.isChecked && formErrors[`trackedThrough-${globalIndex}`] && (
                            <Typography color="error" variant="caption">
                              {formErrors[`trackedThrough-${globalIndex}`]}
                            </Typography>
                          )}
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth error={!!formErrors[`status-${globalIndex}`]}>
                          <InputLabel>Status</InputLabel>
                          <Select
                            value={enquiry.status || ""}
                            onChange={(event) => handleDropdownChange(event, "status", globalIndex)}
                            label="Status"
                          >
                            <MenuItem value="">Select Status</MenuItem>
                            {statusOptions.map((status) => (
                              <MenuItem key={status} value={status}>
                                {status === "Super Hot" ? "Interested" : status}
                              </MenuItem>
                            ))}
                          </Select>
                          {enquiry.isChecked && formErrors[`status-${globalIndex}`] && (
                            <Typography color="error" variant="caption">
                              {formErrors[`status-${globalIndex}`]}
                            </Typography>
                          )}
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth error={!!formErrors[`assignedTo-${globalIndex}`]}>
                          <InputLabel>Assigned To</InputLabel>
                          <Select
                            value={enquiry.assignedTo || ""}
                            onChange={(event) => handleDropdownChange(event, "assignedTo", globalIndex)}
                            label="Assigned To"
                          >
                            <MenuItem value="">--Select--</MenuItem>
                            {assignedToOptions.map((option) => (
                              <MenuItem key={option.userId} value={option.employeeName}>
                                {option.employeeName}
                              </MenuItem>
                            ))}
                          </Select>
                          {enquiry.isChecked && formErrors[`assignedTo-${globalIndex}`] && (
                            <Typography color="error" variant="caption">
                              {formErrors[`assignedTo-${globalIndex}`]}
                            </Typography>
                          )}
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={enquiry.remark || ""}
                          onChange={(event) => handleRemarksChange(event, globalIndex)}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <FaEye
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => handleEyeClick(enquiry.enquiryId)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel>Rows per page</InputLabel>
              <Select
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
                label="Rows per page"
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
            <Pagination
              count={Math.ceil(filteredData.length / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              color="primary"
            />
          </Box>
        </Paper>
      )}

      {!loading && isSearchPerformed && enquiryData.length === 0 && (
        <Typography variant="body1" sx={{ mt: 2 }}>
          No enquiries found for the selected criteria.
        </Typography>
      )}

      <Box sx={{ mt: 3 }}>
        <Button variant="contained" color="primary" onClick={handleSave} sx={{ mr: 2 }}>
          Save
        </Button>
        <Button variant="outlined" color="primary" onClick={handleReset}>
          Reset
        </Button>
      </Box>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: 400,
            borderRadius: 4,
            textAlign: "center",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Enquiry Details
          </Typography>
          <TableContainer>
            <Table aria-label="enquiry details">
              <TableBody>
                {selectedEnquiry ? (
                  <>
                    <TableRow>
                      <TableCell>Enquiry Code:</TableCell>
                      <TableCell>{selectedEnquiry.enquiryCode}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Parent/Guardian Name:</TableCell>
                      <TableCell>{selectedEnquiry.parent_GaurdianName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Enquiry Follow Up Date:</TableCell>
                      <TableCell>{selectedEnquiry.enquiryFollowUpDate}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Remarks:</TableCell>
                      <TableCell>{selectedEnquiry.remarks}</TableCell>
                    </TableRow>
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={2}>No data found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </Container>
  );
}

export default EnquiryFollow;
