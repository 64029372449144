import React from 'react';
import { useState, useEffect } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, MenuItem, Select, Button, TextField, Grid, } from '@mui/material';
import { Modal, Box, Typography } from '@mui/material';
import { saveAs } from 'file-saver';

function AdmReport() {
  const [selectedOption, setSelectedOption] = React.useState('all');
  const [selectedClass, setSelectedClass] = React.useState('');
  const [selectedCaste, setSelectedCaste] = React.useState('');
  const [selectedGender, setSelectedGender] = React.useState('');
  const [selectedStream, setSelectedStream] = React.useState(0);
  const [selectedSession, setSelectedSession] = React.useState('');
  const [isEWS, setIsEWS] = React.useState('no');
  const [fromDate, setFromDate] = React.useState('');
  const [toDate, setToDate] = React.useState('');
  const [ddlClass, setDdlClass] = useState([]);
  const [ddlCaste, setDdlCaste] = useState([]);
  const [ddlGender, setDdlGender] = useState([]);
  const [ddlStream, setDdlStream] = useState([]);
  const [ddlSession, setDdlSession] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [reportData, setReportData] = useState(null);
  const [isCasteFilter, setIsCasteFilter] = useState(false); // Single radio for caste filter
  const [isGenderFilter, setIsGenderFilter] = useState(false); // Single radio for gender filter
  const [isStreamFilter, setIsStreamFilter] = useState(false); // Single radio for stream filter

  const token = sessionStorage.getItem('token');
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const fromDateRef = React.useRef(null);
  const toDateRef = React.useRef(null);

 /// Function to format date to DD/MM/YYYY
  const formatToDDMMYYYY = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const setDefaultDates = () => {
    const today = new Date();
    const defaultToDate = new Date(today);
    const defaultFromDate = new Date(today);
    defaultFromDate.setMonth(defaultFromDate.getMonth() - 1); // One month before today

    const selectedSessionObj = ddlSession.find(
      (session) => session.financialYearID === selectedSession
    );
    let minDate, maxDate;
    if (selectedSessionObj) {
      const [startYear, endYear] = selectedSessionObj.finanacialYear.split('-');
      minDate = new Date(startYear, 3, 1); // April 1st of start year
      maxDate = new Date(endYear, 2, 31); // March 31st of end year
    } else {
      const currentYear = today.getFullYear();
      const financialYearStart = new Date(currentYear, 3, 1);
      const financialYearEnd = new Date(currentYear + 1, 2, 31);
      const todayIsInCurrentFinancialYear = today >= financialYearStart && today <= financialYearEnd;
      const startYear = todayIsInCurrentFinancialYear ? currentYear : currentYear - 1;
      const endYear = todayIsInCurrentFinancialYear ? currentYear + 1 : currentYear;
      minDate = new Date(startYear, 3, 1);
      maxDate = new Date(endYear, 2, 31);
    }

    if (defaultFromDate < minDate) {
      defaultFromDate.setTime(minDate.getTime());
    }
    if (defaultToDate > maxDate) {
      defaultToDate.setTime(maxDate.getTime());
    }

    setFromDate(formatToDDMMYYYY(defaultFromDate));
    setToDate(formatToDDMMYYYY(defaultToDate));
  };

  const fetchSchoolDetails = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${apiUrl}/FeeReport/GetSchoolName`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({}),
      });
      if (!response.ok) {
        throw new Error(`Error fetching financial years: ${response.status}`);
      }
      const data = await response.json();
      setSchoolData(data);
    } catch (error) {
      console.error(error);
    }
  };


  const fetchDdlClass = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${apiUrl}/Enquiry/ddlClassName`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({}),
      });
      if (!response.ok) {
        throw new Error(`Error fetching classes: ${response.status}`);
      }
      const data = await response.json();
      setDdlClass(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDdlCaste = async () => {
    try {
      const response = await fetch(`${apiUrl}/Caste/GetCaste`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({}),
      });
      const data = await response.json();
      setDdlCaste(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDdlGender = async () => {
    try {
      const response = await fetch(`${apiUrl}/Admission/ddlGender`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({}),
      });
      const data = await response.json();
      setDdlGender(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDdlStream = async () => {
    try {
      const response = await fetch(`${apiUrl}/Stream/GetAllStreams`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({}),
      });
      if (!response.ok) {
        throw new Error(`Error fetching streams: ${response.status}`);
      }
      const data = await response.json();
      console.log('Fetched Streams:', data); // Should log the array you provided
      setDdlStream(data);
    } catch (error) {
      console.error('Error fetching streams:', error);
    }
  };

  const fetchDdlSession = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/ClassPromotion/GetFinancialYear`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({}),
        }
      );

      if (!response.ok) {
        throw new Error(`Error fetching financial years: ${response.status}`);
      }

      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        console.error("Record Not Found");
        return;
      }
      setDdlSession(data);

      const today = new Date();
      const currentYear = today.getFullYear();
      const nextYear = currentYear + 1;

      const financialYearStart = new Date(currentYear, 3, 1);
      const financialYearEnd = new Date(nextYear, 2, 31);

      let currentSessionString;
      if (today >= financialYearStart && today <= financialYearEnd) {
        currentSessionString = `${currentYear}-${nextYear}`;
      } else {
        currentSessionString = `${currentYear - 1}-${currentYear}`;
      }

      const currentSession = data.find(
        (item) => item.finanacialYear === currentSessionString
      );

      if (currentSession) {
        setSelectedSession(currentSession.financialYearID);
        setDefaultDates();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFinancialYearRange = () => {
    const selectedSessionObj = ddlSession.find(
      (session) => session.financialYearID === selectedSession
    );
    
    if (!selectedSessionObj) return { minDate: null, maxDate: null };
    
    const [startYear, endYear] = selectedSessionObj.finanacialYear.split('-');
    const minDate = new Date(startYear, 3, 1); // April 1st
    const maxDate = new Date(endYear, 2, 31); // March 31st
    
    return { minDate, maxDate };
  };

  const handleOptionChange = (event) => setSelectedOption(event.target.value);
  const handleClassChange = (event) => setSelectedClass(event.target.value);
  const handleCasteChange = (event) => setSelectedCaste(event.target.value);
  const handleGenderChange = (event) => setSelectedGender(event.target.value);
  const handleStreamChange = (event) => {
    const value = Number(event.target.value); // Converts "7" to 7, etc.
    setSelectedStream(value);
  };
   const handleSessionChange = (event) => {
    setSelectedSession(event.target.value);
    setDefaultDates();
  };
  const handleEWSChange = (event) => setIsEWS(event.target.value);
  const handleCasteFilterToggle = () => {
    setIsCasteFilter((prev) => {
      const newValue = !prev;
      if (!newValue) setSelectedCaste('');
      return newValue;
    });
  };
  const handleGenderFilterToggle = () => {
    setIsGenderFilter((prev) => {
      const newValue = !prev;
      if (!newValue) setSelectedGender('');
      return newValue;
    });
  };
  const handleStreamFilterToggle = () => {
    setIsStreamFilter((prev) => {
      const newValue = !prev;
      if (!newValue) setSelectedStream(0); // Reset to 0 when filter is disabled
      return newValue;
    });
  };

  const handleFromDateChange = (event) => {
    const [year, month, day] = event.target.value.split('-');
    const newDate = new Date(year, month - 1, day);
    setFromDate(formatToDDMMYYYY(newDate));
    if (fromDateRef.current) fromDateRef.current.blur();
  };

  const handleToDateChange = (event) => {
    const [year, month, day] = event.target.value.split('-');
    const newDate = new Date(year, month - 1, day);
    setToDate(formatToDDMMYYYY(newDate));
    if (toDateRef.current) toDateRef.current.blur();
  };

  useEffect(() => {
    fetchDdlClass();
    fetchSchoolDetails();
    fetchDdlCaste();
    fetchDdlGender();
    fetchDdlStream();
    fetchDdlSession();
  }, []);

  const formatDate = (dateString) => {
    return dateString; // Already in DD/MM/YYYY format
  };
  
/// Check if Generate Report button should be disabled
const isGenerateDisabled = () => {
  if (isCasteFilter && !selectedCaste) return true;
  if (isGenderFilter && !selectedGender) return true;
  if (isStreamFilter && !selectedStream) return true;
  return false;
};

const handleSave = async () => {
  try {
    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);
    const requestBody = {
      classId: selectedClass || 0,
      casteId: selectedCaste || 0,
      gender: selectedGender || '',
      streamId: selectedStream === 0 ? 0 : selectedStream,
      sessionId: selectedSession || 0,
      isEWS: isEWS === 'yes' ? 1 : 0,
      fromDate: formattedFromDate,
      toDate: formattedToDate,
      isAll: selectedOption === 'all'
    };
    const response = await fetch(`${apiUrl}/FeeReport/ReportAdmission`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(requestBody),
    });
    if (!response.ok) {
      alert(`Error: Please provide proper input`);
      return;
    }

    const data = await response.json();

    if (data.msg === "Record Not Found") {
      alert('No records found');
      return;
    }

    setReportData(data);

    const reportWindow = window.open('', '_blank');
    reportWindow.document.write(`
      <html>
        <head>
          <title>Fee Report</title>
          <style>
            @media print {
              body { font-family: Arial, sans-serif; }
              .form-container { width: 100%; margin: 0; }
              .navbar { background-color: #3f51b5; }
              th { background-color: #673ab7; color: #ffffff; }
            }
            body { font-family: Arial, sans-serif; }
            .form-container { border: 1px solid #ddd; padding: 20px; width: 100%; margin: 0 auto; overflow: auto; }
            .navbar { display: flex; justify-content: space-between; align-items: center; padding: 10px; background-color: #3f51b5; border-bottom: 1px solid #ddd; }
            .logo { width: 50px; height: auto; }
            .school-name { font-size: 20px; font-weight: bold; color: #ffffff; flex-grow: 1; text-align: center; }
            table { border-collapse: collapse; width: 100%; }
            th, td { border: 1px solid #dddddd; text-align: left; padding: 8px; font-size: 12px; }
            th { background-color: #673ab7; color: #ffffff; font-size: 14px; }
            .print-button { background-color: #4CAF50; border: none; color: white; padding: 10px 20px; text-align: center; text-decoration: none; font-size: 14px; margin-top: 20px; cursor: pointer; display: flex; align-items: center; }
            .print-button-container { display: flex; justify-content: flex-end; margin-top: 20px; }
          </style>
        </head>
        <body>
          <div class="form-container">
            ${schoolData.map((item) => `
              <div class="navbar">
                <img class="logo" src="https://arizshad-002-site5.ktempurl.com/${item.headerLogoImg}" alt="Logo" />
                <div class="school-name">${item.schoolName}</div>
              </div>
            `).join('')}
            <table>
              <thead>
                <tr>
                  <th>Admission No</th>
                  <th>Student Name</th>
                  <th>Mobile No</th>
                  <th>Roll No</th>
                  <th>Class</th>
                  <th>Section</th>
                  <th>Father Name</th>
                  <th>Mother Name</th>
                  <th>Transport</th>
                  <th>Caste</th>
                  <th>Stream</th>
                  <th>Language Type</th>
                  <th>Gender</th>
                  <th>EWS</th>
                  <th>TC Issued</th>
                </tr>
              </thead>
              <tbody>
                ${data.map(item => `
                  <tr>
                    <td>${item.admissionNo !== undefined && item.admissionNo !== null ? item.admissionNo : ' - '}</td>
                    <td>${item.studentName !== undefined && item.studentName !== null ? item.studentName : ' - '}</td>
                    <td>${item.mobileNo !== undefined && item.mobileNo !== null ? item.mobileNo : ' - '}</td>
                    <td>${item.rollNo !== undefined && item.rollNo !== null ? item.rollNo : ' - '}</td>
                    <td>${item.className !== undefined && item.className !== null ? item.className : ' - '}</td>
                    <td>${item.section !== undefined && item.section !== null ? item.section : ' - '}</td>
                    <td>${item.fathersName !== undefined && item.fathersName !== null ? item.fathersName : ' - '}</td>
                    <td>${item.mothersName !== undefined && item.mothersName !== null ? item.mothersName : ' - '}</td>
                    <td>${item.isTransport !== undefined && item.isTransport !== null ? (item.isTransport === 'Y' ? 'Yes' : 'No') : ' - '}</td>
                    <td>${item.casteName !== undefined && item.casteName !== null ? item.casteName : ' - '}</td>
                    <td>${item.stream !== undefined && item.stream !== null ? item.stream : ' - '}</td>
                    <td>${item.languageType !== undefined && item.languageType !== null ? item.languageType : ' - '}</td>
                    <td>${item.gender !== undefined && item.gender !== null ? item.gender : ' - '}</td>
                    <td>${item.ews !== undefined && item.ews !== null ? (item.ews === 'Y' ? 'Yes' : 'No') : ' - '}</td>
                    <td>${item.isTcIssued !== undefined && item.isTcIssued !== null ? (item.isTcIssued === 'Y' ? 'Yes' : 'No') : ' - '}</td>
                  </tr>
                `).join('')}
              </tbody>
            </table>
          </div>
          <div class="print-button-container">
            <button class="print-button" onclick="window.print()">Print</button>
          </div>
        </body>
      </html>
    `);
    reportWindow.document.close();
  } catch (error) {
    alert('Error fetching report data:', error);
  }
};

  const generateCSV = () => {
    if (!reportData || !Array.isArray(reportData) || reportData.length === 0) {
      console.error('No report data available');
      return;
    }

    let csvContent = '';
    const keys = Object.keys(reportData[0]);
    csvContent += keys.map(key => key.toUpperCase()).join(',') + '\n';
    reportData.forEach(item => {
      const values = keys.map(key => item[key]);
      csvContent += values.join(',') + '\n';
    });
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'report.csv');
  };
  
  const buttonStyles = {
    generate: {
      background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
      borderRadius: "25px",
      padding: "10px 25px",
      width: "150px",
      boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
      transition: "all 0.3s ease",
      "&:hover": {
        background: "linear-gradient(45deg, #1976D2 30%, #1E88E5 90%)",
        transform: "translateY(-2px)",
        boxShadow: "0 5px 10px rgba(33, 203, 243, .4)",
      },
    },
    csv: {
      background: "linear-gradient(45deg, #f44336 30%, #ef5350 90%)",
      borderRadius: "25px",
      padding: "10px 25px",
      width: "150px",
      boxShadow: "0 3px 5px 2px rgba(244, 67, 54, .3)",
      transition: "all 0.3s ease",
      "&:hover": {
        background: "linear-gradient(45deg, #d32f2f 30%, #e53935 90%)",
        transform: "translateY(-2px)",
        boxShadow: "0 5px 10px rgba(244, 67, 54, .4)",
      },
    },
  };
  
  const selectStyles = {
    width: "250px",
    background: "linear-gradient(45deg, #ffffff 30%, #f5f5f5 90%)",
    borderRadius: "15px",
    boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
    transition: "all 0.3s ease",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2196F3",
      borderWidth: "2px",
    },
    "&:hover": {
      boxShadow: "0 6px 12px rgba(0,0,0,0.15)",
      transform: "translateY(-2px)",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#1976D2",
      },
    },
    "&.Mui-focused": {
      boxShadow: "0 6px 12px rgba(33, 150, 243, 0.3)",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#21CBF3",
      },
    },
    "& .MuiSelect-select": {
      padding: "12px 16px",
      fontWeight: 500,
      color: "#333",
    },
  };


  return (
    <Grid container justifyContent="center" spacing={2} style={{margin: '0 auto', maxWidth: '600px'}}>
      <Grid item xs={12} sm={6}>
        <FormControl component="fieldset">
          <RadioGroup row aria-label="student" name="student" value={selectedOption} onChange={handleOptionChange}>
            <FormControlLabel value="all" control={<Radio />} label="All Students" />
            <FormControlLabel value="class" control={<Radio />} label="Class-wise Students" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        {selectedOption === 'class' && (
          <FormControl fullWidth>
            <Select value={selectedClass} onChange={handleClassChange} sx={selectStyles} displayEmpty>
            <MenuItem value="" disabled>Select Class</MenuItem>
              {ddlClass.map((classItem) => (
                <MenuItem key={classItem.classId} value={classItem.classId}>
                  {classItem.className}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl component="fieldset">
          <RadioGroup row value={isEWS} onChange={handleEWSChange}>
            <FormControlLabel value="yes" control={<Radio />} label="EWS: Yes" />
            <FormControlLabel value="no" control={<Radio />} label="EWS: No" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl component="fieldset">
          <FormControlLabel
            control={<Radio checked={isCasteFilter} onClick={handleCasteFilterToggle} />}
            label="Filter by Caste"
          />
        </FormControl>
      </Grid>
      {isCasteFilter && (
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Select value={selectedCaste} onChange={handleCasteChange} sx={selectStyles} displayEmpty>
              <MenuItem value="" disabled>Select Caste</MenuItem>
              {ddlCaste.map((caste) => (
                <MenuItem key={caste.casteId} value={caste.casteId}>
                  {caste.casteName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <FormControl component="fieldset">
          <FormControlLabel
            control={<Radio checked={isGenderFilter} onClick={handleGenderFilterToggle} />}
            label="Filter by Gender"
          />
        </FormControl>
      </Grid>
      {isGenderFilter && (
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Select value={selectedGender} onChange={handleGenderChange} sx={selectStyles} displayEmpty>
              <MenuItem value="" disabled>Select Gender</MenuItem>
              {ddlGender.map((gender) => (
                <MenuItem key={gender.genderId} value={gender.gender}>
                  {gender.gender}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <FormControl component="fieldset">
          <FormControlLabel
            control={<Radio checked={isStreamFilter} onClick={handleStreamFilterToggle} />}
            label="Filter by Stream"
          />
        </FormControl>
      </Grid>
      {isStreamFilter && (
  <Grid item xs={12} sm={6}>
    <FormControl fullWidth>
      <Select value={selectedStream} onChange={handleStreamChange} sx={selectStyles} displayEmpty>
        <MenuItem value={0} disabled>Select Stream</MenuItem>
        {ddlStream.map((stream) => (
          <MenuItem key={stream.streamID} value={stream.streamID}>
            {stream.streamName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Grid>
)}
      <Grid item xs={12} sm={6}>
      <FormControl fullWidth>
          <Select value={selectedSession} onChange={handleSessionChange} sx={selectStyles} displayEmpty>
            <MenuItem value="" disabled label="Session"
            >Select Session</MenuItem>
            {ddlSession.map((session) => (
              <MenuItem key={session.financialYearID} value={session.financialYearID}>
                {session.finanacialYear} 
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
          <TextField
            label="From Date"
            type="date"
            inputRef={fromDateRef}
            value={fromDate ? fromDate.split('/').reverse().join('-') : ''}
            onChange={handleFromDateChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            sx={selectStyles}
            inputProps={{
              min: selectedSession && getFinancialYearRange().minDate.toISOString().split('T')[0],
              max: selectedSession && getFinancialYearRange().maxDate.toISOString().split('T')[0]
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="To Date"
            type="date"
            inputRef={toDateRef}
            value={toDate ? toDate.split('/').reverse().join('-') : ''}
            onChange={handleToDateChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            sx={selectStyles}
            inputProps={{
              min: selectedSession && getFinancialYearRange().minDate.toISOString().split('T')[0],
              max: selectedSession && getFinancialYearRange().maxDate.toISOString().split('T')[0]
            }}
          />
        </Grid>

      <Grid item xs={12} container spacing={1} justifyContent="center"  sx={{ marginBottom: 1, marginTop: 1 }}>
  <Grid item xs={6} sm={4}>
  <Button 
  variant="contained" 
  color="primary" 
  onClick={handleSave} 
  fullWidth 
  size="medium"
  sx={{ ...buttonStyles.generate, minWidth: '150px' }} 
>
  Generate Report
</Button>

  </Grid>
  <Grid item xs={6} sm={2}>
    <Button 
      variant="contained" 
      color="error" 
      onClick={generateCSV} 
      fullWidth 
      size="medium" 
      sx={{ ...buttonStyles.csv, minWidth: '150px' }} 
    >
      Download CSV
    </Button>
  </Grid>
</Grid>
    </Grid>
  );
}
export default AdmReport;
